import { THEME_LOCAL_STORAGE_KEY } from "@App/Constants/GlobalConstants";
import {
  SIDEBAR_MAX_HEIGHT,
  SIDEBAR_MIN_HEIGHT,
} from "@App/Constants/SidebarConstants";
import { LayoutContext, UserContext } from "@App/Context/AppContext";
import useLocalStorage from "@App/Hooks/useLocalStorage";
import { LayoutContextType, UserContextType } from "@App/Types/AppContextType";
import {
  ArrowLeftOnRectangleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  MoonIcon,
  SunIcon,
  UserIcon,
} from "@heroicons/react/20/solid";
import { animated, useSpring } from "@react-spring/web";
import { useCallback, useContext } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

function Header() {
  // check if dark mode is enabled
  // const isDarkMode = useDarkMode();
  const navigate = useNavigate();
  // User & Layout Context Consumers
  const { layoutState, layoutDispatch } = useContext<
    LayoutContextType | undefined
  >(LayoutContext);
  const { userState, userDispatch } = useContext<UserContextType | undefined>(
    UserContext
  );
  const { setItemToLocalstorage } = useLocalStorage(
    THEME_LOCAL_STORAGE_KEY,
    undefined
  );
  const location = useLocation();

  const currentSidebarState = layoutState.Sidebar;
  const currentThemeState = layoutState.Theme;
  console.log(currentThemeState);

  // Page Animations using React Spring
  const headerAnimation = useSpring({
    marginLeft:
      location.pathname === "/" && !userState.user?.Token
        ? "0px"
        : currentSidebarState.isCollapsed
        ? `${SIDEBAR_MIN_HEIGHT}`
        : `${SIDEBAR_MAX_HEIGHT}`,
  });

  const sidebarTogglerAnimation = useSpring({
    left: currentSidebarState.isCollapsed ? "55px" : "233px",
  });

  // Page & Layout handlers
  const toggleSidebar = useCallback(() => {
    layoutDispatch({
      type: "SIDEBAR_TOGGLE",
      payload: {
        ...currentSidebarState,
        isCollapsed: !currentSidebarState.isCollapsed,
      },
    });
  }, [currentSidebarState, layoutDispatch]);

  const logOutUser = useCallback(() => {
    userDispatch({
      type: "LOGOUT_USER",
    });
    navigate("/login");
  }, [userDispatch]);

  const darkMode = useCallback(() => {
    if (currentThemeState.dark) {
      setItemToLocalstorage("light");
      document.documentElement.classList.remove("dark");
    } else {
      setItemToLocalstorage("dark");
      document.documentElement.classList.add("dark");
    }
    layoutDispatch({
      type: "THEME",
      payload: {
        dark: currentThemeState.dark,
      },
    });
  }, [currentThemeState.dark, layoutDispatch, setItemToLocalstorage]);

  return (
    <animated.header
      style={headerAnimation}
      className="shadow sticky inset-0 z-20"
    >
      <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
        <animated.div
          style={sidebarTogglerAnimation}
          className={`sidebar-toggler fixed z-10 top-[19px] bg-white dark:bg-gray-600 shadow-xl p-1 rounded-full w-6 h-6 cursor-pointer ${
            location.pathname === "/" && !userState.user?.Token && "hidden"
          }`}
          onClick={() => toggleSidebar()}
        >
          {currentSidebarState.isCollapsed ? (
            <ChevronRightIcon className="h-full w-full dark:fill-white" />
          ) : (
            <ChevronLeftIcon className="h-full w-full dark:fill-white" />
          )}
        </animated.div>
        <div className="flex justify-end items-center">
          <div className="flex items-center">
            <button
              aria-label="darkmode"
              className="flex items-center p-2 text-xs font-medium text-gray-700 bg-white  rounded-lg hover:bg-gray-100 hover:text-primary-700  dark:bg-gray-800 focus:outline-none dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              onClick={() => darkMode()}
            >
              {currentThemeState.dark ? (
                <SunIcon className="w-5 h-5 fill-current" />
              ) : (
                <MoonIcon className="w-5 h-5 fill-current" />
              )}
            </button>

            {!userState.user?.Token && (
              <NavLink
                to={"/login"}
                className="ml-2 inline-flex text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-7 py-1.5 text-center dark:focus:ring-primary-900"
              >
                <span>Login</span>
              </NavLink>
            )}
          </div>
          {userState.user?.Token && (
            <>
              <div className="divide-x border h-5 w-[1px] border-gray-200 dark:border-gray-700 ml-2"></div>
              <button
                aria-label="logout"
                className="ml-2 flex items-center p-2 text-xs font-medium text-gray-700 bg-white  rounded-lg hover:bg-gray-100 hover:text-primary-700  dark:bg-gray-800 focus:outline-none dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                onClick={() => logOutUser()}
              >
                <ArrowLeftOnRectangleIcon className="w-5 h-5 fill-current" />
              </button>
              <div className="divide-x border h-5 w-[1px] border-gray-200 dark:border-gray-700 ml-2"></div>
              <button
                aria-label="profile"
                className="ml-2 flex items-center p-2 text-xs font-medium text-gray-700 bg-white  rounded-lg hover:bg-gray-100 hover:text-primary-700  dark:bg-gray-800 focus:outline-none dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                <UserIcon className="w-5 h-5 fill-current" />
              </button>
            </>
          )}
        </div>
      </nav>
    </animated.header>
  );
}

export default Header;
