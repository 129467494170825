// ComponentLoader.js
import React from "react";
import LoaderComponent from "../UI/LoaderComponent";

const ComponentLoader = (importComponent) => {
  const Component = React.lazy(importComponent);

  return (props) => (
    <React.Suspense fallback={<LoaderComponent />}>
      <Component {...props} />
    </React.Suspense>
  );
};

export default ComponentLoader;
