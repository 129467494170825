import { FABComponentType } from "@App/Types/ComponentType";
import { PlusIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";

const Fab = ({ onClick }: FABComponentType) => {
  const [scrollY, setScrollY] = useState(0);
  const [isIconVisible, setIsIconVisible] = useState(true);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Adjust the threshold as needed
    setIsIconVisible(scrollY < 100);
  }, [scrollY]);

  return (
    <div className={`fixed bottom-8 right-4 transition-opacity duration-300 `}>
      <div
        onClick={onClick}
        className={`flex items-center p-4 rounded-full transition-opacity shadow-md cursor-pointer text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium text-sm text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800`}
      >
        <PlusIcon height={20} width={20} />

        {/* Your FAB Title (conditionally rendered based on visibility) */}
        {isIconVisible && <span className={`ml-2 text-lg `}>Add Tutorial</span>}
      </div>
    </div>
  );
};

export default Fab;
