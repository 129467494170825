// Import the functions you need from the SDKs you need
import * as firebase from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCpmeX_pTOCmrs8QKf0gCFLoT2iWtIk3OM",
  authDomain: "meshilhelpapp.firebaseapp.com",
  projectId: "meshilhelpapp",
  storageBucket: "meshilhelpapp.appspot.com",
  messagingSenderId: "574696388932",
  appId: "1:574696388932:web:66df60d0edc7d9eeaeff11",
  measurementId: "G-WKQGJTQE5W",
};

export default firebase;
