import App from "@App/App";
import useAuth from "@App/Hooks/useAuth";
import TutorialList from "@App/Pages/AdminPortal/Tutorials/TutorialList";
import UnAuthorizedPage from "@App/Pages/UnAuthorizedPage";
import { Suspense } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import LoaderComponent from "../UI/LoaderComponent";
import ComponentLoader from "./ComponentLoader";
import PrivateRoute from "./PrivateRoute";
export default function RouterWrapper() {
  const isLoggedIn = useAuth();

  const LoginPage = ComponentLoader(
    () => import("@Pages/AdminPortal/LoginPage")
  );
  const UserAccountPage = ComponentLoader(
    () => import("@Pages/AdminPortal/UserAccount/UserAccountList")
  );
  const ProductListPage = ComponentLoader(
    () => import("@Pages/AdminPortal/Products/ProductList")
  );
  const MenuLinksPage = ComponentLoader(
    () => import("@Pages/AdminPortal/MenuLinks/MenuLinks")
  );
  const ErrorPage = ComponentLoader(() => import("@Pages/ErrorPage"));
  const PublicPortalPDFPage = ComponentLoader(
    () => import("@Pages/PublicPortal/PublicPortalPDFPage")
  );
  const ServerErrorPage = ComponentLoader(
    () => import("@Pages/ServerErrorPage")
  );
  const MeshilLandingPage = ComponentLoader(
    () => import("@Pages/PublicPortal/MeshilLandingPage")
  );
  return (
    <Router>
      <Suspense fallback={<LoaderComponent />}>
        <Routes>
          <Route
            path="login"
            element={isLoggedIn.user ? <Navigate to="/" /> : <LoginPage />}
          />
          <Route path="/" element={<App />}>
            <Route index element={<MeshilLandingPage />} />
            <Route element={<PrivateRoute />}>
              <Route path="users" element={<UserAccountPage />} />
              <Route path="products" element={<ProductListPage />} />
              <Route path="menu-links" element={<MenuLinksPage />} />
              <Route path="tutorials" element={<TutorialList />} />
            </Route>

            {/* Public Routes accessible to all users */}
            <Route path="public" element={<Navigate to="/" />} />
            <Route
              path="public/:productCode"
              element={<PublicPortalPDFPage />}
            />
            <Route
              path="public/:productCode/:menuLinkID"
              element={<PublicPortalPDFPage />}
            />
          </Route>
          <Route path="*" element={<ErrorPage />} />
          <Route path="500" element={<ServerErrorPage />} />
          <Route path="unauthorized" element={<UnAuthorizedPage />} />
        </Routes>
      </Suspense>
    </Router>
  );
}
