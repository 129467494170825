// Reference: qurhan.com - http://www.qurhan.com/ - Github: https://github.com/qurhah

type QueryObject = Record<string, any>;
type Config = {
  eq?: string;
  sep?: string;
  fn?: StringifyFunction;
  prefix?: string;
};
export type StringifyFunction = (key: string, value: any) => any;
const defaultStringifyFunction: StringifyFunction = (key: string, value: any) =>
  value;
const defaultEq = "=";
const defaultSep = "&";
const defaultConfig: Config = {
  eq: defaultEq,
  sep: defaultSep,
  prefix: "",
  fn: defaultStringifyFunction,
};
function stringify(obj: QueryObject, config = defaultConfig): string {
  const { eq, sep, fn, prefix } = { ...defaultConfig, ...config };
  if (obj == null || !isObject(obj)) {
    return "";
  }
  return Object.entries(obj)
    .filter(([, value]) => value !== null) // filter out null values
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return encode(key, value.join(","), { eq, fn, prefix });
      }

      if (isObject(value)) {
        return stringify(value, { eq, sep, fn, prefix: getKey(key, prefix) });
      }
      return encode(key, value, { eq, fn, prefix });
    })
    .join(sep);
}

function isObject(obj: QueryObject) {
  const type = typeof obj;
  return !!obj && (type === "function" || type === "object");
}

// encode the key and value of a query object
const encode = (
  key: string,
  value: any,
  { eq = defaultEq, fn = defaultStringifyFunction, prefix = "" }
): string => {
  const newValue = encodeURIComponent(fn(key, value));
  const newKey = getKey(key, prefix);

  return [newKey, newValue].join(eq);
};

// encode the key and add prefix if necessary
const getKey = (key: string, prefix = ""): string => {
  const encodedKey = encodeURIComponent(key);
  if (prefix) return `${prefix}[${encodedKey}]`;
  return encodedKey;
};

export default stringify;
