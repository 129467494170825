import { ComponentStyleType } from "@App/Types/ComponentType";
import { useState } from "react";

export default function useSnackBar() {
  const [snackbarProps, setSnackbarProps] = useState<{
    show: boolean;
    message: string;
    SnackBarType: ComponentStyleType;
  }>({
    message: "",
    show: false,
    SnackBarType: "success",
  });

  const handleSnackbar = (
    message: string = "",
    SnackBarType: ComponentStyleType = "success",
    duration: number = 3000
  ) => {
    setSnackbarProps({
      message,
      show: true,
      SnackBarType,
    });
    let id = setTimeout(() => {
      setSnackbarProps({
        message: "",
        show: false,
        SnackBarType: "success",
      });
    }, duration);
    return () => clearTimeout(id);
  };

  return { snackbarProps, handleSnackbar };
}
