import ButtonComponent from "@App/Components/UI/ButtonComponent";
import DialogComponent from "@App/Components/UI/Dialog/DialogComponent";
import DropDownComponent from "@App/Components/UI/Dropdown/DropDownComponent";
import DropDownItemComponent from "@App/Components/UI/Dropdown/DropDownItemComponent";
import Fab from "@App/Components/UI/FABComponent";
import FileUploadComponent from "@App/Components/UI/FileUpload/FileUploadComponent";
import FileViewComponent from "@App/Components/UI/FileUpload/FileViewComponent";
import InputComponent from "@App/Components/UI/InputComponent";
import InputErrorMessageComponent from "@App/Components/UI/InputErrorMessageComponent";
import LoaderComponent from "@App/Components/UI/LoaderComponent";
import NoDataScreen from "@App/Components/UI/NodataComponent";
import Skeleton from "@App/Components/UI/Skeleton/SkeletonComponent";
import SnackbarComponent from "@App/Components/UI/SnackbarComponent";
import { BadgeColorClassList } from "@App/Constants/Tailwind/UIClasses";
import useFetch from "@App/Hooks/useFetch";
import useSnackBar from "@App/Hooks/useSnackBar";
import { PageType } from "@App/Types/ComponentType";
import {
  PaginatorStateType,
  ProductTutorialType,
  ProductType,
} from "@App/Types/GlobalType";
import {
  GetFormData,
  ProducTutorialListUrl,
  ProductListUrl,
  UpdateProductTutorialUrl,
} from "@App/Utils/apiPaths";
import { getBaseUrl } from "@App/Utils/url";
import {
  CheckIcon,
  ExclamationCircleIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import { useFormik } from "formik";
import { Suspense, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const TutorialList = () => {
  // Our Products List
  const [tutorialList, setTutorialList] = useState<
    Partial<PaginatorStateType<ProductTutorialType[]>>
  >({
    tableData: [],
    pageStart: 1,
    pageEnd: 10,
    pageIndex: 1,
    pageSize: 10,
    paginatedData: [],
    skeletonData: [],
    totalLength: 0,
  });

  let ImageBaseUrl = getBaseUrl("Assets/Products/");
  const { t } = useTranslation();

  const [productList, setProductList] = useState<ProductType[]>([]);
  const { snackbarProps, handleSnackbar } = useSnackBar();

  const handleSelectedItem = (key: string, value: string) => {
    console.log("selected", key, value);
    tutorialForm.setFieldValue("ProductID", key);
  };

  const handleProductChanges = (key: string, value: string) => {
    console.log("selected", key, value);
    getProductTutorialList(Number(key));
  };

  const { updateFetchInput, setLoading, loading, setError } = useFetch();

  const getProductTutorialList = async (ProductID?: number) => {
    try {
      setTutorialList({
        tableData: [],
        pageStart: 1,
        pageEnd: 10,
        pageIndex: 1,
        pageSize: 10,
        paginatedData: [],
        skeletonData: SKELETON_INITIAL_DATA,
        totalLength: 0,
      });

      const response = await updateFetchInput<ProductTutorialType[]>({
        input: ProducTutorialListUrl(ProductID || 0),
        init: {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
      });

      console.log(response);

      if (response != null) {
        if (response.ok) {
          setLoading(false);
          setTutorialList({
            ...tutorialList,
            totalLength: response.response.length,
            tableData: response.response,
            paginatedData: response.response.slice(0, 10),
          });
        } else {
          setLoading(false);
          setError(response.message);
        }
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      // Handle the error as needed
    }
  };

  // Product List
  const getProductList = async () => {
    try {
      const response = await updateFetchInput<ProductType[]>({
        input: ProductListUrl(0),
        init: {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
      });
      if (response != null) {
        if (response.ok) {
          setLoading(false);
          setProductList(response.response);
        } else {
          setLoading(false);
          setError(response.message);
        }
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const SKELETON_INITIAL_DATA: ProductTutorialType[] = [];

  useEffect(() => {
    const init = async () => {
      try {
        await getProductList();
      } catch (error) {
        // Handle the error as needed
        console.error(error);
      }
    };

    init();
  }, []);

  // Add new user
  let [isOpen, setIsOpen] = useState(false);
  let [deleteDialogOpen, setDeleteDialog] = useState(false);
  const [fileViewerDialogOpen, setFileViewerDialogOpen] = useState(false);
  const [fileViewerProps, setFileViewerProps] = useState<{
    FileURL: string;
    FileType: string;
  }>({
    FileURL: "",
    FileType: "",
  });

  const tutorialInitalValue: ProductTutorialType = {
    ProductTutorialID: 0,
    Title: "",
    Subtitle: "",
    Description: "",
    ProductID: null,
    VideoUrl: "",
  };

  const tutorialForm = useFormik<ProductTutorialType>({
    initialValues: tutorialInitalValue,
    validationSchema: Yup.object({
      ProductID: Yup.string().required("Required"),
      Title: Yup.string().required("Required"),
      Subtitle: Yup.string().required("Required"),
      Description: Yup.string().required("Required").max(500),
      VideoUrl: Yup.string().required("Required"),
    }),
    onSubmit: async (_) => {
      console.log(tutorialForm.values, tutorialForm.isValid);

      if (tutorialForm.isValid) {
        try {
          const url = UpdateProductTutorialUrl();
          let formData = GetFormData(tutorialForm.values);
          const response = await updateFetchInput<number>({
            input: url,
            init: {
              method: "POST",
              body: formData,
            },
          });

          console.log(response);

          if (response != null) {
            if (response.ok) {
              console.log(tutorialForm);

              handleSnackbar(
                `Tutorial ${
                  tutorialForm.values.ProductTutorialID <= 0
                    ? "Added"
                    : "Edited"
                } Successfully`,
                "success"
              );
              await getProductTutorialList(tutorialForm.values.ProductID);
              tutorialForm.resetForm({
                values: tutorialInitalValue,
              });
              setIsOpen(false);
            } else {
              setLoading(false);
              handleSnackbar(response.message, "danger");
              setError(response.message);
            }
          }
        } catch (error) {
          console.error(error);
          setLoading(false);
          handleSnackbar("Something Went wrong!..", "danger");
        }
      }
    },
  });

  const UploadFileHandler = useCallback(
    (file: File) => {
      console.log(file);
      tutorialForm.setFieldValue(
        "VideoUrl",
        "https://firebasestorage.googleapis.com/v0/b/meshilhelpapp.appspot.com/o/tutorial%2Fimage%20optimizer%20demo.mp4?alt=media&token=5396ee6a-4823-4f84-abef-4e7062856d03"
      );
      // console.log(productForm.values);

      // upload the video file to firebase storage.
      // const storageRef = ref(storage, `tutorial/${file.name}`);
      // const uploadTask = uploadBytesResumable(storageRef, file);

      // uploadTask.on(
      //   "state_changed",
      //   (snapshot) => {
      //     const percent = Math.round(
      //       (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      //     );

      //     // update progress
      //     // setPercent(percent);
      //     console.log(percent);
      //   },
      //   (err) => console.log(err),
      //   () => {
      //     // download url
      //     getDownloadURL(uploadTask.snapshot.ref).then((url) => {
      //       console.log(url);
      //     });
      //   }
      // );
    },
    [] //Empty dependency array means the function is memoized once during component initialization
  );

  const FileViewerHandler = useCallback(
    (fileURL: string, FileType: string) => {
      console.log(fileURL, FileType);
      setFileViewerDialogOpen(true);
      setFileViewerProps({
        FileType,
        FileURL: fileURL,
      });
    },
    [] //Empty dependency array means the function is memoized once during component initialization
  );

  return (
    <Suspense fallback={<LoaderComponent />}>
      <section className="bg-white dark:bg-gray-900 sm:p-5 mr-0 min-h-screen">
        <div className="mx-auto max-w-screen-xl lg:py-16 lg:px-6 ">
          {!loading && tutorialList.tableData?.length != 0 && (
            <div className="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                Tutorials
              </h2>
              <p className="font-light text-gray-500 lg:mb-16 sm:text-xl dark:text-gray-400">
                Embark on a Learning Journey: Step-by-Step Tutorials to Uncover
                the Intricacies of OnlineTCS Functionality.
              </p>
            </div>
          )}

          <DropDownComponent
            selectedOptionHandler={handleProductChanges}
            className="w-1/3 mb-5"
          >
            {productList.map((product) => (
              <DropDownItemComponent
                keyID={product.ProductID.toString()}
                value={product.ProductName}
              />
            ))}
          </DropDownComponent>

          <div className="grid gap-8 mb-6 lg:mb-16 md:grid-cols-2 lg:grid-cols-3">
            {!loading
              ? tutorialList.tableData.map((tutorial, index) => (
                  // <div
                  //   key={index}
                  //   className="items-center bg-gray-50 rounded-lg shadow flex dark:bg-gray-800 dark:border-gray-700 max-h-[200px]"
                  // >
                  //   <a
                  //     href="#"
                  //     className={`h-full w-[20%] flex items-center justify-center ${
                  //       BadgeColorClassList[index % BadgeColorClassList.length]
                  //     }`}
                  //   >
                  //     <PlayCircleIcon className="w-full h-full object-cover scale-75" />
                  //   </a>
                  //   <div
                  //     className={`p-5 flex flex-col justify-between w-[80%]`}
                  //   >
                  //     <div>
                  //       <h3 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                  //         <a href="#">{tutorial.Title}</a>
                  //       </h3>
                  //       <span className="text-gray-500 dark:text-gray-400">
                  //         {tutorial.Subtitle}
                  //       </span>
                  //     </div>
                  //     <p className="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400 line-clamp-3">
                  //       Lorem, ipsum dolor sit amet consectetur adipisicing
                  //       elit. Nisi, ab. Lorem, ipsum dolor sit amet consectetur
                  //       adipisicing elit. Nisi, ab. Lorem, ipsum dolor sit amet
                  //       consectetur adipisicing elit. Nisi, ab. Lorem, ipsum
                  //       dolor sit amet consectetur adipisicing elit. Nisi, ab.
                  //       Lorem, ipsum dolor sit amet consectetur adipisicing
                  //       elit. Nisi, ab.
                  //     </p>
                  //   </div>
                  // </div>

                  <div
                    key={index}
                    className="items-center bg-gray-50 rounded-lg shadow flex dark:bg-gray-800 dark:border-gray-700 max-h-[200px]"
                  >
                    <a
                      href="#"
                      className={`flex-shrink-0 w-1/4 h-full flex items-center justify-center ${
                        BadgeColorClassList[index % BadgeColorClassList.length]
                      }`}
                    >
                      <PlayCircleIcon className="w-full h-full object-cover scale-75" />
                    </a>
                    <div className="flex-1 p-5 flex flex-col justify-between">
                      <div>
                        <h3 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                          <a href="#">{tutorial.Title}</a>
                        </h3>
                        <span className="text-gray-500 dark:text-gray-400">
                          {tutorial.Subtitle}
                        </span>
                      </div>
                      <p className="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400 line-clamp-3">
                        {tutorial.Description}
                      </p>
                    </div>
                  </div>
                ))
              : tutorialList.skeletonData.map((skeleton, index) => (
                  <div
                    className="card rounded-lg p-4 bg-white dark:bg-gray-800 cursor-pointer border dark:border-gray-700 border-gray-300 shadow-sm"
                    key={index}
                  >
                    <Skeleton
                      type="container"
                      as="div"
                      width="w-auto"
                      isShown={loading}
                      className={`mb-4 w-full rounded-lg h-56`}
                      dynamicElementClassNames="w-full rounded-lg h-56 mb-2"
                    />

                    <Skeleton
                      type="line"
                      as="h3"
                      isShown={loading}
                      height="h-4"
                      dynamicElementClassNames="mb-2"
                    />
                    <Skeleton
                      type="line"
                      as="span"
                      isShown={loading}
                      height="h-4"
                      width="w-40"
                    />
                  </div>
                ))}
          </div>

          {!loading && tutorialList.tableData?.length === 0 && (
            <NoDataScreen
              title="No Tutorial Found"
              description="Add new tutorial by clicking the 'Add Tutorial' button"
            />
          )}

          <Fab onClick={() => setIsOpen(true)} />

          <DialogComponent
            isOpen={isOpen}
            dialogHandler={setIsOpen}
            dialogTitle={`${
              tutorialForm.values.isView
                ? "Show"
                : tutorialForm.values.ProductTutorialID > 0
                ? "Edit"
                : "Add"
            } tutorial`}
            width="w-[600px]"
          >
            <form onSubmit={tutorialForm.handleSubmit} name="Add Tutorial">
              <div className="grid gap-4 mb-4 sm:grid-cols-2">
                <div className="sm:col-span-2">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-left">
                    Products
                  </label>
                  <DropDownComponent
                    selectedOptionHandler={handleSelectedItem}
                    className="w-full"
                  >
                    {productList.map((product) => (
                      <DropDownItemComponent
                        keyID={product.ProductID.toString()}
                        value={product.ProductName}
                      />
                    ))}
                  </DropDownComponent>
                  {tutorialForm.touched.ProductID &&
                  tutorialForm.errors.ProductID ? (
                    <InputErrorMessageComponent
                      ErrorMessage={tutorialForm.errors.ProductID}
                      ErrorType="danger"
                      ClassName="text-xs mt-1 mb-0 text-left"
                    />
                  ) : null}
                </div>

                <div>
                  <InputComponent
                    disabled={tutorialForm.values.isView}
                    InputStyleType="success"
                    placeholder="Title"
                    LabelName="Title"
                    type="text"
                    name="Title"
                    id="Title"
                    onChange={tutorialForm.handleChange}
                    value={tutorialForm.values.Title}
                  />
                  {tutorialForm.touched.Title && tutorialForm.errors.Title ? (
                    <InputErrorMessageComponent
                      ErrorMessage={tutorialForm.errors.Title}
                      ErrorType="danger"
                      ClassName="text-xs mt-1 mb-0 text-left"
                    />
                  ) : null}
                </div>

                <div>
                  <InputComponent
                    disabled={tutorialForm.values.isView}
                    InputStyleType="success"
                    placeholder="Subtitle"
                    LabelName="Subtitle"
                    type="text"
                    name="Subtitle"
                    id="Subtitle"
                    onChange={tutorialForm.handleChange}
                    value={tutorialForm.values.Subtitle}
                  />
                  {tutorialForm.touched.Subtitle &&
                  tutorialForm.errors.Subtitle ? (
                    <InputErrorMessageComponent
                      ErrorMessage={tutorialForm.errors.Subtitle}
                      ErrorType="danger"
                      ClassName="text-xs mt-1 mb-0 text-left"
                    />
                  ) : null}
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="Description"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-left"
                  >
                    Description
                  </label>
                  <textarea
                    disabled={tutorialForm.values.isView}
                    onChange={tutorialForm.handleChange}
                    value={tutorialForm.values.Description}
                    id="Description"
                    name="Description"
                    rows={2}
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white focus:ring-1 focus:outline-none focus:ring-primary-600 focus:border-primary-600 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Write tutorial description here"
                  ></textarea>

                  <div
                    className={`flex items-center ${
                      tutorialForm.errors.Description
                        ? "justify-between"
                        : "justify-end"
                    }`}
                  >
                    {tutorialForm.errors.Description &&
                    tutorialForm.touched.Description ? (
                      <InputErrorMessageComponent
                        ErrorMessage={tutorialForm.errors.Description}
                        ErrorType="danger"
                        ClassName="text-xs mt-1 mb-0 text-left"
                      />
                    ) : null}

                    <div className="text-right text-sm text-gray-500">
                      {tutorialForm.values.Description?.length}/{500}
                    </div>
                  </div>
                </div>

                <FileUploadComponent
                  FileUploadHandler={UploadFileHandler}
                  FileViewHandler={FileViewerHandler}
                  Action={
                    tutorialForm.values.isView
                      ? PageType.VIEW
                      : tutorialForm.values.ProductTutorialID > 0
                      ? PageType.EDIT
                      : PageType.ADD
                  }
                  FileFullPath={
                    tutorialForm.values.ProductTutorialID > 0
                      ? ImageBaseUrl + tutorialForm.values.VideoUrl
                      : ""
                  }
                  isChange={isOpen}
                  FileTypeAllowed={["video.*", "application.*"]}
                />
              </div>

              <div
                className={`${
                  tutorialForm.values.isView ? "hidden" : "flex justify-end"
                }`}
              >
                <ButtonComponent
                  type="button"
                  onClick={() => {
                    setIsOpen(false);
                    tutorialForm.resetForm();
                  }}
                  buttonStyleType="secondary"
                  label="Cancel"
                  ClassName="px-4 py-2 inline-flex items-center"
                />

                <ButtonComponent
                  disabled={loading}
                  type="submit"
                  buttonStyleType="primary"
                  onLoadingState={loading}
                  label={`${t("tutorial.addTutorial")}`}
                  ClassName="px-5 py-2.5 inline-flex items-center ml-2"
                />
              </div>
            </form>
          </DialogComponent>

          <DialogComponent
            isOpen={fileViewerDialogOpen}
            dialogHandler={setFileViewerDialogOpen}
            dialogTitle={`File Viewer`}
            width="w-[600px]"
          >
            <FileViewComponent
              FileURL={fileViewerProps.FileURL}
              FileType={fileViewerProps.FileType}
            />
          </DialogComponent>

          <SnackbarComponent
            showSnackBar={snackbarProps.show}
            SnackBarType={snackbarProps.SnackBarType}
            Icon={
              snackbarProps.SnackBarType === "success"
                ? CheckIcon
                : ExclamationCircleIcon
            }
            ScreenReaderLabel="Info"
            Label={snackbarProps.message}
            PositionClass="bottom-0 left-1/2 -translate-x-1/2"
            className="w-50 fixed"
          />
        </div>
      </section>
    </Suspense>
  );
};

export default TutorialList;
