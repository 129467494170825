import { InputTailwindClass } from "@Constants/Tailwind/UIClasses";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import { InputComponentType } from "@Types/ComponentType";
import { useState } from "react";

export default function InputComponent({
  InputStyleType,
  isLabeled = true,
  LabelName,
  className = "",
  parentClassName = "",
  ...props
}: InputComponentType) {
  const { name, type, value } = props;
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  // console.log(InputTailwindClass[type as typeof InputStyleType],InputTailwindClass, InputStyleType);

  return (
    <>
      {isLabeled && (
        <label
          htmlFor={name}
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-left"
        >
          {LabelName ?? name}
        </label>
      )}
      <div className={`relative ${parentClassName}`}>
        <input
          {...props}
          type={showPassword ? "text" : type || "text"}
          className={`${className} ${InputTailwindClass[InputStyleType]}`}
        />
        {type === "password" && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
            {showPassword ? (
              <EyeIcon
                className="h-5 w-5 text-gray-500 cursor-pointer"
                onClick={togglePasswordVisibility}
              />
            ) : (
              <EyeSlashIcon
                className="h-5 w-5 text-gray-500 cursor-pointer"
                onClick={togglePasswordVisibility}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}
