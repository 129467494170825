/*
File Upload Component is used to upload files to the server.
It is used in the following components:
    - Drag and Drop Component
    - File Animation
    - PDF & Image Preview
    - Upload server time tracking
*/

import {
  CardColorOnlyPalette,
  CardColorPalette,
  FILE_UPLOAD_TYPES,
  MAX_FILE_UPLOAD_SIZE,
} from "@App/Constants/GlobalConstants";
import {
  FileUploadComponentType,
  FileUploadStatusEnum,
  FilehandlerType,
  PageType,
} from "@App/Types/ComponentType";
import {
  CloudArrowUpIcon,
  DocumentIcon,
  FaceFrownIcon,
  PhotoIcon,
  VideoCameraIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import React, { memo, useEffect, useState } from "react";
import "./FileUploadComponent.css";

const FileUploadComponent = ({
  FormikFormControl,
  FileViewHandler,
  FileUploadHandler,
  FileFullPath,
  FileTypeAllowed = FILE_UPLOAD_TYPES,
  isChange,
  Action,
  dynamicFileInputID,
  className = "",
}: FileUploadComponentType) => {
  // objectURL variable
  let objectURL: string | undefined = undefined;
  let dynamicFileInputName: string = dynamicFileInputID ?? "file";

  // file handling
  const [file, setFile] = useState<File | null>(null);
  const [files, setFiles] = useState<
    {
      file: File;
    }[]
  >([]);
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const [fileUploadStatus, setFileUploadStatus] = useState<FilehandlerType>(
    {} as FilehandlerType
  );
  const [fileTypeMatchChecker, setFileTypeMatchChecker] =
    useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [UploadProgress, setUploadProgress] = useState<number>(0);
  const [fileColor, setFileColor] = useState<string>(CardColorOnlyPalette[0]);
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);

  const [dragging, setDragging] = useState(false);

  const [currentState, setCurrentState] = useState<any>(null);

  /* Load time effect */
  useEffect(() => {
    // Check if the file is already uploaded on the first load
    async function CheckFileExists() {
      console.log("changes action");

      if (
        FileFullPath !== "" &&
        FileFullPath != null &&
        Action != PageType.ADD
      ) {
        console.log("here");
        setIsFirstTime(false);
        let FileName = (FileFullPath as string)?.split("/").pop().split(".")[0];
        let FileFromUrl = await GetFileFromUrl(FileFullPath, FileName);
        console.log("FileFromUrl", FileFromUrl, FileFullPath);

        if (FileFromUrl != null) {
          setFile(FileFromUrl);
          setFiles([
            {
              file: FileFromUrl,
            },
          ]);
          setImageUrl(URL.createObjectURL(FileFromUrl));
          if (FileTypeAllowed.some((file) => FileFromUrl.type.match(file))) {
            console.log("fileFromUrl", FileFromUrl);

            setFileTypeMatchChecker(true);
            setFileColor(RandomColorSelector());
            let fileList: any = [FileFromUrl];

            handleFileChange(fileList, "fetch");
          } else {
            setFileUploadStatus({
              FileUploadStatus: "File type not allowed",
              FileList: [],
              FileName: FileName,
              FileType: FileFromUrl.type,
              Name: FileName,
              FileSize: FileFromUrl.size,
            });
            setFileTypeMatchChecker(false);
          }
        } else {
          setFileUploadStatus({
            FileUploadStatus: FileUploadStatusEnum.failure,
            FileList: [],
            FileName: FileName,
            FileType: "",
            Name: FileName,
            FileSize: 0,
          });
          setFileTypeMatchChecker(false);
        }
      } else {
        setIsFirstTime(true);
        setFile(null);
        setFiles([]);
      }
    }

    CheckFileExists();
  }, [isChange]);

  // browse file handling
  const handleBrowseFile = () => {
    console.log("browse", FormikFormControl);
    // setCurrentState(FormikFormControl.values);
    // clear the input field to allow selecting the same file again
    (document.getElementById(dynamicFileInputName) as HTMLInputElement).value =
      "";
    document.getElementById(dynamicFileInputName)?.click();

    // assuming the uploading on the first time
    setIsFirstTime(true);
  };

  /**
   * Simulate the upload process
   */

  function uploadFilesSimulator(Filehandler: FilehandlerType) {
    setIsUploading(true);
    let index = 0;

    const progressInterval = setInterval(() => {
      if (UploadProgress >= 100) {
        clearInterval(progressInterval);
        setUploadProgress(0);
        if (index === Filehandler.FileList.length) {
          setIsUploading(false);
          console.log("All files uploaded:", files);
        }
      } else {
        setUploadProgress((progress) => progress + 20);
      }
    }, 200);

    FileUploadHandler(Filehandler.DocFile, FormikFormControl);

    setTimeout(() => {
      clearInterval(progressInterval);
      index++;
      setUploadProgress(0);
      console.log("timeout index", index);
      FileUploadHandler(Filehandler.DocFile, FormikFormControl);
      setIsUploading(false);

      // if (index === Filehandler.FileList.length) {
      //   setIsUploading(false);
      //   // FormikFormControl.setFieldValue("HelpFile", Filehandler.DocFile);
      //   FileUploadHandler(Filehandler.DocFile, FormikFormControl);
      //   console.log("All files uploaded:", files);
      // } else {
      //   uploadFilesSimulator(Filehandler);
      // }
    }, 1000);
  }

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement> | FileList,
    dragOrbrowse: "drag" | "browse" | "fetch" = "browse"
  ) => {
    console.log("file change event", currentState);

    let FileStatus = FileHandler(event, dragOrbrowse, FileTypeAllowed);
    console.log(FileStatus);
    setFileUploadStatus(FileStatus);

    let {
      FileUploadStatus,
      DocFile,
      FileList,
      FileName,
      FileType,
      Name,
      FileSize,
    } = FileStatus;

    // set the file and its details
    setFile(DocFile);
    setFiles(FileList);

    if (FileUploadStatus === FileUploadStatusEnum.success) {
      // set the image url
      setImageUrl(URL.createObjectURL(DocFile));

      // File type match checker
      setFileTypeMatchChecker(
        FileUploadStatus === FileUploadStatusEnum.success
      );

      // Upload Status indicator
      setIsUploading(true);
      setFileColor(RandomColorSelector());
      // FileUploadHandler(FileStatus.DocFile, FormikFormControl);
      uploadFilesSimulator(FileStatus);
    }
  };

  const ClearFile = () => {
    setFile(null);
    setFiles([]);
    setImageUrl(undefined);
    setFileUploadStatus({} as FilehandlerType);
    setFileTypeMatchChecker(false);

    setIsFirstTime(true);
    FileUploadHandler(null);

    // clear the input field to allow selecting the same file again
    document.getElementById(dynamicFileInputName)?.setAttribute("value", "");
  };

  // drag and drop handling

  const handleDragEnter = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    const files = event.dataTransfer.files;
    console.log(files);

    handleFileChange(files, "drag");
  };

  /* Show Document */
  const ShowDocument = () => {
    if (objectURL) {
      // revoke the old object url to avoid using more memory than needed
      URL.revokeObjectURL(objectURL);
    }

    objectURL = URL.createObjectURL(file);
    console.log(fileUploadStatus, objectURL);

    FileViewHandler(
      isFirstTime ? objectURL : FileFullPath,
      fileUploadStatus.FileType
    );
  };
  return (
    <>
      {file === null && Action != PageType.VIEW && (
        <div
          className={`sm:col-span-2 first-letter: ${
            dragging ? "dragging" : ""
          } ${className}`}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <div className="flex items-center justify-center w-full">
            <label
              htmlFor="dropzone-file"
              className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <CloudArrowUpIcon className="w-10 h-10 mb-3 text-gray-400" />
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">Drag & drop or</span>{" "}
                  <span
                    className="
               text-primary-600 cursor-pointer
                "
                    onClick={handleBrowseFile}
                  >
                    Browse
                  </span>
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  SVG, PNG, JPG or GIF (MAX. 800x400px)
                </p>
              </div>
              <input
                id={dynamicFileInputName}
                type="file"
                className="hidden"
                onChange={handleFileChange}
              />
            </label>
          </div>
        </div>
      )}

      {/* Document View */}
      {fileTypeMatchChecker && file !== null && (
        <div className="sm:col-span-2">
          <div
            className="documentView mb-1 border border-solid border-gray-300 rounded-lg dark:border-gray-600
        flex align-items-center justify-content-center
        "
          >
            <div className="document-details flex grow">
              <div
                onClick={ShowDocument}
                className={`document-box m-auto cursor-pointer`}
                style={{ background: fileColor }}
              >
                <span className="text-center blackAlt-inter-medium-16">
                  {fileUploadStatus.FileType.startsWith("application/") ? (
                    <DocumentIcon className="w-5 h-5 text-white" />
                  ) : fileUploadStatus.FileType.startsWith("video/") ? (
                    <VideoCameraIcon className="w-5 h-5 text-white" />
                  ) : (
                    <PhotoIcon className="w-5 h-5 text-white" />
                  )}
                </span>
              </div>

              <div className="flex items-center justify-between w-full">
                <div className="document-names flex flex-col ml-3 w-full text-left">
                  <span className="fileName">
                    {fileUploadStatus.FileName ?? ""}
                  </span>
                  {!isUploading && (
                    <div className="d-flex justify-content-start">
                      <span className="fileSize">
                        {formatBytes(fileUploadStatus.FileSize ?? 0)}
                      </span>
                      <span className="px-2 fileStatus">
                        &bull;
                        {file !== null ? "Completed" : "Failed"}
                      </span>
                    </div>
                  )}

                  {isUploading && (
                    <div className="progress">
                      <div
                        className="bar"
                        style={{
                          width: `${UploadProgress}%`,
                          background: fileColor,
                        }}
                      ></div>
                    </div>
                  )}
                </div>

                {!isUploading && Action != PageType.VIEW && (
                  <div className="document-actions flex" onClick={ClearFile}>
                    <XCircleIcon className="icon h-5 w-5 ml-auto self-center cursor-pointer" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {
        /* Unsupported File Type View */
        !fileTypeMatchChecker && file !== null && (
          <div className="sm:col-span-2">
            <div className="documentView flex items-center justify-center mb-1 border border-solid border-gray-300 rounded-lg dark:border-gray-600">
              <FaceFrownIcon className="w-8 h-8 text-gray-400" />
              <span className="ml-2 text-sm text-gray-500 dark:text-gray-400">
                {fileUploadStatus.FileUploadStatus ?? "Unsupported File Type"}
              </span>

              {!isUploading && (
                <div className="document-actions flex ml-3" onClick={ClearFile}>
                  <XCircleIcon className="icon h-5 w-5 ml-auto self-center cursor-pointer" />
                </div>
              )}
            </div>
          </div>
        )
      }
    </>
  );
};

export default memo(FileUploadComponent);

/* File Upload Drag & Drop and Browse File Handler */
export const FileHandler = (
  e: React.ChangeEvent<HTMLInputElement> | FileList,
  dragOrbrowse: "drag" | "browse" | "fetch",
  FileTypes: string[] = FILE_UPLOAD_TYPES
): FilehandlerType => {
  let FileList: FileList | React.ChangeEvent<HTMLInputElement>;
  if (dragOrbrowse === "browse") {
    let Event = e["target"];
    FileList = (Event as HTMLInputElement).files;
  } else {
    FileList = e as FileList;
  }

  console.log(FileList);

  let FileStatus: FilehandlerType;
  if ((FileList as FileList).length > 0) {
    if (FileTypes.some((file) => FileList[0].type.match(file))) {
      if (FileList[0].size > MAX_FILE_UPLOAD_SIZE) {
        FileStatus = {
          FileUploadStatus: FileUploadStatusEnum.overflow,
          FileType: "overflow",
        };
      } else {
        FileStatus = {
          FileList: [{ file: FileList[0] }],
          FileUploadStatus: FileUploadStatusEnum.success,
          DocFile: FileList[0],
          Name: FileList[0].name,
          FileType: FileList[0].type,
          FileName: FileNameOnly(FileList[0].name),
          FileSize: FileList[0].size,
        };
      }
    } else {
      FileStatus = {
        FileUploadStatus: FileUploadStatusEnum.warning,
        FileList: [{ file: FileList[0] }],
        FileType: FileList[0].type,
      };
    }
  } else {
    FileStatus = {
      FileUploadStatus: FileUploadStatusEnum.failure,
      FileType: "error",
    };
  }
  return FileStatus;
};

/* File name helper method */
export const FileNameOnly = (fileName: string): string => {
  return fileName.split(".")[0];
};

/* Fetching file from the server / Project Photo folder by using FileUrl */
export const GetFileFromUrl = (
  fileUrl: string,
  fileName: string
): Promise<File> => {
  return fetch(fileUrl)
    .then((val) => val.blob())
    .then((blob) => {
      return new File([blob], `${fileName}.${blob.type.split("/")[1]}`, {
        type: blob.type,
        lastModified: new Date().getTime(),
      });
    });
};

/**
 * format bytes
 * @param bytes (File size in bytes)
 * @param decimals (Decimals point)
 */
export function formatBytes(bytes: number, decimals: number = 2) {
  if (bytes === 0) {
    return "0 Bytes";
  }
  const k = 1024;
  const dm = decimals <= 0 ? 0 : decimals || 2;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const RandomColorSelector = () => {
  let RandomNumber = Math.floor(Math.random() * CardColorPalette.length);
  return CardColorPalette[RandomNumber];
};
