// make url
// api paths
// type literals of links
// fetcher methods

import stringify from "./qs-stringify";
import { getBaseUrl } from "./url";

type apiUrls =
  | "UserAccounts/Login"
  | "UserAccounts/Read"
  | "UserAccounts/Create"
  | "UserAccounts/Update"
  | "UserAccounts/Delete"
  | "UserAccounts/GetRequestToken"
  | "Products/Read"
  | "Products/Create"
  | "Products/Update"
  | "Products/Delete"
  | "ProductsModules/Read"
  | "ProductsModules/Create"
  | "ProductsModules/Update"
  | "ProductsModules/Delete"
  | "ProductMenuLinks/Read"
  | "ProductMenuLinks/Create"
  | "ProductMenuLinks/Update"
  | "ProductMenuLinks/Delete"
  | "PublicPortal/ProductList"
  | "PublicPortal/ProductInfo"
  | "PublicPortal/ProductLinks"
  | "PublicPortal/ProductMenuInfo"
  | "PublicPortal/ProductModules"
  | "ProductTutorial/Read"
  | "ProductTutorial/Update"
  | "ProductTutorial/Delete";

/**
 *
 * @param {apiUrls} url
 * @param {Record<string, unknown>} parameters
 * @returns {string} url with parameters
 */
const makeUrl = (
  url: apiUrls,
  parameters?: Record<string, unknown>
): string => {
  if (!parameters) {
    return getBaseUrl(url);
  }
  return getBaseUrl(`${url}${`?${stringify(parameters)}`}`);
};

// Modified Urls with query parameters

export const GetRequestToken = (LoginName: string): string =>
  makeUrl("UserAccounts/GetRequestToken", {
    LoginName,
  });

/* User Account Urls */
export const UserLoginUrl = (): string => makeUrl("UserAccounts/Login");
export const UserAccountListUrl = (LoginUserID: number): string =>
  makeUrl("UserAccounts/Read", {
    LoginUserID,
  });
export const AddUserAccountUrl = (): string => makeUrl("UserAccounts/Create");
export const UpdateUserAccountUrl = (): string =>
  makeUrl("UserAccounts/Update");
export const DeleteUserAccountUrl = (
  LoginUserID: number,
  UserAccountID: number
): string =>
  makeUrl("UserAccounts/Delete", {
    LoginUserID,
    UserAccountID,
  });

/* Products Urls */
export const ProductListUrl = (LoginUserID: number): string =>
  makeUrl("Products/Read", {
    LoginUserID,
  });
export const AddProductUrl = (): string => makeUrl("Products/Create");
export const UpdateProductUrl = (): string => makeUrl("Products/Update");
export const DeleteProductUrl = (
  ProductID: number,
  LoginUserID: number
): string =>
  makeUrl("Products/Delete", {
    LoginUserID,
    ProductID,
  });

/* Products Modules Urls */
export const ProductModulesListUrl = (
  ProductID: number,
  LoginUserID: number
): string =>
  makeUrl("ProductsModules/Read", {
    ProductID,
    LoginUserID,
  });

export const AddProductModuleUrl = (): string =>
  makeUrl("ProductsModules/Create");
export const UpdateProductModuleUrl = (): string =>
  makeUrl("ProductsModules/Update");
export const DeleteProductModuleUrl = (
  ModuleID: number,
  LoginUserID: number
): string =>
  makeUrl("ProductsModules/Delete", {
    LoginUserID,
    ModuleID,
  });

/* Product Menu Links Urls */
export const ProductMenuLinksListUrl = (
  ModuleID: number,
  LoginUserID: number
): string =>
  makeUrl("ProductMenuLinks/Read", {
    ModuleID,
    LoginUserID,
  });

export const AddProductMenuLinkUrl = (): string =>
  makeUrl("ProductMenuLinks/Create");
export const UpdateProductMenuLinkUrl = (): string =>
  makeUrl("ProductMenuLinks/Update");

export const DeleteProductMenuLinkUrl = (
  ProductLinkID: number,
  LoginUserID: number
): string =>
  makeUrl("ProductMenuLinks/Delete", {
    LoginUserID,
    ProductLinkID,
  });

/* Product Tutorial Urls */
export const ProducTutorialListUrl = (ProductID: number): string =>
  makeUrl("ProductTutorial/Read", {
    ProductID,
  });

export const UpdateProductTutorialUrl = (): string =>
  makeUrl("ProductTutorial/Update");

export const DeleteProductTutorialUrl = (ProductTutorialID: number): string =>
  makeUrl("ProductTutorial/Delete", {
    ProductTutorialID,
  });

/* Public Portal Urls */
export const PublicPortalProductListUrl = (): string =>
  makeUrl("PublicPortal/ProductList");

export const PublicPoralProductInfoUrl = (ProductCode: string): string =>
  makeUrl("PublicPortal/ProductInfo", {
    ProductCode,
  });

export const PublicPortalProductLinksUrl = (ProductCode: string): string =>
  makeUrl("PublicPortal/ProductLinks", {
    ProductCode,
  });

export const PublicPortalProductMenuInfoUrl = (
  ProductCode: string,
  MenuLinkID: string
): string =>
  makeUrl("PublicPortal/ProductMenuInfo", {
    ProductCode,
    MenuLinkID,
  });

export const PublicPortalProductModulesUrl = (ProductCode: string): string =>
  makeUrl("PublicPortal/ProductModules", {
    ProductCode,
  });

/* Formdata Generator */
export function GetFormData(data) {
  let formdata = new FormData();
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      let arr: [] = data[key];
      arr?.forEach((obj, index) => {
        if (obj != null) {
          Object.keys(obj).forEach((el) => {
            formdata.set(`${key}[${index}][${el}]`, obj[el]);
          });
        } else {
          formdata.append(`${key}[${index}]`, obj);
        }
      });
    } else {
      formdata.set(key, data[key]);
    }
  });
  return formdata;
}
