import { DropDownComponentType } from "@App/Types/ComponentType";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useRef, useState } from "react";

const DropDownComponent = ({
  children,
  className,
  selectedOptionHandler,
}: DropDownComponentType) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const dropdownRef = useRef(null);

  const options = ["Option 1", "Option 2", "Option 3"];

  const handleOptionClick = (key, value) => {
    console.log("clicked", key, value);

    setSelectedOption(value);
    setIsOpen(false);

    /* Pass the selected option to the parent component */
    selectedOptionHandler(key, value);
  };

  // Handle click outside of the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div
      className={`relative inline-block text-left z-10 ${className}`}
      ref={dropdownRef}
    >
      <button
        type="button"
        className="bg-gray-50 border inline-flex items-center justify-between border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-1 focus:outline-none focus:ring-primary-600 focus:border-primary-600 w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedOption ? selectedOption : "Select an option"}
        <ChevronDownIcon className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
      </button>
      {isOpen && (
        <div className="origin-top-left absolute left-0 mt-2 w-full bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {React.Children.map(children, (child) => {
              if (React.isValidElement(child)) {
                return React.cloneElement(child, {
                  handleOptionClick: handleOptionClick,
                } as any);
              }
              return child;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

// text-white w-full bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center justify-between dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800

export default DropDownComponent;
