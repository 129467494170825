import { DialogComponentType } from "@App/Types/ComponentType";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useRef } from "react";
import ReactDOM from "react-dom";
import { Transition } from "react-transition-group";

export default function DialogComponent({
  isOpen,
  isHeader = true,
  dialogTitle,
  children,
  width,
  dialogHandler,
}: DialogComponentType) {
  function closeModal() {
    dialogHandler(false);
  }

  // react transition
  const TransitionRef = useRef(null);
  const transitionStyles = {
    entering: "opacity-0 hidden",
    entered: "opacity-100 visible",
    exiting: "opacity-100 visible",
    exited: "opacity-0 hidden",
  };
  const transitionDuration = 100;

  /* createPortal is used to render the component in the body of the html page instead of as a child to parent element */
  return ReactDOM.createPortal(
    <Transition
      in={isOpen}
      timeout={transitionDuration}
      nodeRef={TransitionRef}
    >
      {(state) => (
        <div
          ref={TransitionRef}
          className={`fixed z-10 top-0 left-0 w-full h-full overflow-y-auto bg-opacity-70 bg-gray-900 transition-opacity duration-300 ${transitionStyles[state]}`}
        >
          <div className="flex min-h-full items-center justify-center text-center">
            <div
              className={`relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 max-w-5xl md:h-auto ${width}`}
            >
              {isHeader && (
                <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    {dialogTitle}
                  </h3>
                  <button
                    onClick={closeModal}
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-toggle="defaultModal"
                  >
                    <XMarkIcon className="w-5 h-5 fill-current" />
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
              )}

              {children}
            </div>
          </div>
        </div>
      )}
    </Transition>,
    document.body
  );
}
