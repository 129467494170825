import { UserStateType } from "@App/Types/AppContextType";
import { UserContext } from "@Context/AppContext";
import React from "react";

function useAuth(): UserStateType {
  const userContext = React.useContext(UserContext);
  return userContext.userState;
}

export default useAuth;
