/* Tailwind UI classes for different design types ('primary' | 'secondary' | 'success' | 'danger' | 'warning') */

import {
  ButtonStyleType,
  ComponentStyleType,
  StateIndicatorStyleType,
  TableItemStyleType,
} from "../../Types/ComponentType";

// Input Component
export const InputTailwindClass: Partial<Record<ComponentStyleType, string>> = {
  success:
    "bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-1 focus:outline-none focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500",
};

// Input Error Message Component
export const InputErrorTailwindClass: Partial<
  Record<ComponentStyleType, string>
> = {
  info: "text-blue-800 dark:text-blue-400",
  danger: "text-red-800 dark:text-red-400",
};

// Alert Component
export const StateIndicatorTailwindClass: Partial<
  Record<ComponentStyleType | StateIndicatorStyleType, string>
> = {
  info: "flex text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-700 dark:text-blue-400",
  danger:
    "flex text-red-800 rounded-lg bg-red-50 dark:bg-gray-700 dark:text-red-400",
  "state-info":
    "flex text-blue-800 rounded-lg bg-blue-100 dark:bg-blue-900 dark:text-blue-300",
  "state-danger":
    "flex text-red-800 rounded-lg bg-red-100 dark:bg-red-900 dark:text-red-300",
  "state-success":
    "flex text-green-800 rounded-lg bg-green-100 dark:bg-green-900 dark:text-green-300",
  "state-warning":
    "flex text-yellow-800 rounded-lg bg-yellow-100 dark:bg-yellow-900 dark:text-yellow-300",
};

// Button Component
export const ButtonTailwindClass: Partial<Record<ButtonStyleType, string>> = {
  primary:
    "text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800",
  secondary:
    "text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600",
};
// Table Component
export const TableTailwindClass: Partial<Record<TableItemStyleType, string>> = {
  table: "w-full text-sm text-left text-gray-500 dark:text-gray-400",
  thead:
    "text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400",
  th: "px-4 py-2.5",
  tr: "border-b dark:border-gray-700",
  td: "px-4 py-3",
};

// Paginator Component
export const PaginatorTailwindClass = {
  previous:
    "flex cursor-pointer select-none items-center justify-center h-full py-2 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white",
  next: "flex cursor-pointer select-none items-center justify-center h-full py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white",
  item: "flex cursor-pointer select-none items-center justify-center text-sm py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white",
  itemSelected:
    "flex cursor-pointer select-none items-center justify-center text-sm z-10 py-2 px-3 leading-tight text-primary-600 bg-primary-50 border border-primary-300 hover:bg-primary-100 hover:text-primary-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white",
};

// Snackbar Component
export const SnackbarTailwindClass: Record<ComponentStyleType, string> = {
  success:
    "text-white rounded-lg bg-green-600 dark:bg-green-800 dark:text-white",
  danger: "text-white rounded-lg bg-red-600 dark:bg-red-800 dark:text-white",
  dark: "text-white rounded-lg bg-gray-800 dark:bg-gray-900",
  info: "text-white rounded-lg bg-blue-600 dark:bg-blue-800 dark:text-white",
  warning:
    "text-white rounded-lg bg-yellow-600 dark:bg-yellow-800 dark:text-white",
};

// Badge Component
export const BadgeTailwindClass = {
  default:
    "bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300",
  dark: "bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300",
  red: "bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300",
  green:
    "bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300",
  yellow:
    "bg-yellow-100 text-yellow-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300",
  indigo:
    "bg-indigo-100 text-indigo-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-indigo-900 dark:text-indigo-300",
  purple:
    "bg-purple-100 text-purple-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300",
  pink: "bg-pink-100 text-pink-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-pink-900 dark:text-pink-300",
};

export const BadgeColorClass = {
  default: "bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300",
  dark: "bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300",
  red: "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300",
  green: "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300",
  yellow:
    "bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300",
  indigo:
    "bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-300",
  purple:
    "bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-300",
  pink: "bg-pink-100 text-pink-800 dark:bg-pink-900 dark:text-pink-300",
};

export const BadgeColorClassList = [
  "bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300",
  "bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300",
  "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300",
  "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300",
  "bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300",
  "bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-300",
  "bg-pink-100 text-pink-800 dark:bg-pink-900 dark:text-pink-300",
  "bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-300",
];
