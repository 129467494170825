import { STATUS_CODE_MESSAGE } from "@App/Constants/GlobalConstants";
import { UserContext } from "@App/Context/AppContext";
import { UserContextType } from "@App/Types/AppContextType";
import { GetRequestToken } from "@App/Utils/apiPaths";
import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const OFFLINE_ERROR = "OFFLINE";

type fetchInputType = {
  input: RequestInfo | URL;
  init?: RequestInit | undefined;
};

type fetchResponseType<T extends unknown> = {
  message: string;
  response: T | null;
  ok: boolean;
};

const useFetch = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { userState, userDispatch } = useContext<UserContextType | undefined>(
    UserContext
  );
  const navigate = useNavigate();
  const location = useLocation();

  const updateFetchInput = async <T extends unknown>(
    fetchInput: fetchInputType
  ): Promise<fetchResponseType<T>> => {
    if (!fetchInput) {
      return { message: "No input provided", response: null, ok: false };
    }

    setLoading(true);
    setError(null);

    try {
      if (typeof window !== "undefined" && !window.navigator.onLine) {
        throw new Error(OFFLINE_ERROR);
      }

      const response: Response = await fetch(fetchInput.input, {
        ...fetchInput.init,
        headers: {
          ...fetchInput.init?.headers,
          Authorization: `Bearer ${userState.user?.Token}`,
        },
      });

      if (response.ok || response.status === 200) {
        const responseData = await response.json();
        return {
          message: STATUS_CODE_MESSAGE[response.status],
          response: responseData,
          ok: true,
        };
      } else {
        if (response.status === 401) {
          try {
            const res = await fetch(GetRequestToken(userState.user.LoginName), {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            });
            const newToken = await res.text();

            if (!newToken) {
              navigate("/login");
            } else {
              userDispatch({
                type: "LOGIN_USER",
                payload: {
                  ...userState.user,
                  Token: newToken,
                },
              });
              // navigate(location.pathname); // Navigate back to the page that caused the error
            }
          } catch (err) {
            navigate("/unauthorized", { state: { from: location } }); // Navigate to unauthorized page and pass the previous location
          }
        }
        const responseData = await response.json();
        return {
          message: STATUS_CODE_MESSAGE[response.status] || "Error occurred",
          response: responseData,
          ok: false,
        };
      }
    } catch (err) {
      setError(err.message);
      return { message: err.message, response: null, ok: false };
    } finally {
      setLoading(false);
    }
  };

  return { loading, setLoading, updateFetchInput, error, setError };
};

export default useFetch;
