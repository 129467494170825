// Login error status message

/* BASE URL */
export const BASE_URL: string = document.getElementsByTagName("base")[0].href;
export const DEV_BASE_URL: string = "http://localhost:5000/";

export const LOGIN_ERROR_STATUS_MESSAGE: { [key: number | string]: string } = {
  [-1]: "Server Error",
  1: "username or password you entered is incorrect",
  2: "User is Deactivated",
};

export const MENU_LINKS_STATUS_MESSAGE: { [key: number | string]: string } = {
  [-10]: "Menu Link name already exists, please try another one",
  [-11]: "Menu LinkID already exists, please try another one",
};

// http status code message
export const STATUS_CODE_MESSAGE = {
  200: "Success",
  400: "Not Found",
  500: "Server Error",

  401: "User Unauthorized",
  404: "Not Found or Bad Request",
};

// LocalStorage key
export const USER_LOCAL_STORAGE_KEY: string = "user";
export const THEME_LOCAL_STORAGE_KEY: string = "theme";

// File Upload Status
export const MAX_FILE_UPLOAD_SIZE = 10 * 1024 * 1024; // 10 MB
export const ACCEPTED_FILE_TYPES: string[] = ["image.*", "application.*"];
export const FILE_UPLOAD_TYPES: string[] = ["application.*", "image.*"];

/* Color Palatte constants
 */

export const CardColorPalette: string[] = [
  "linear-gradient(90deg, #0c67f1 0%, #00b8ee 100%)",
  "linear-gradient(90deg, #f8495e 0%,#ff719b 100%)",
  "linear-gradient(90deg, #339d6a 0%,#1ddea0 100%)",
  "linear-gradient(90deg, #4f5ddf 0%,#8995ff 99.99%)",
  "linear-gradient(90deg, #f46b45 0%, #eea849 100%)",
  "linear-gradient(90deg, #E29587 0%, #D66D75 100%)",
  "linear-gradient(90deg, #9733EE 0%, #DA22FF)",
];

export const CardColorOnlyPalette: string[] = [
  "#0c67f1",
  "#f8495e",
  "#339d6a",
  "#4f5ddf",
  "#f46b45",
  "#E29587",
  "#9733EE",
];

export const colorToHexString = {
  "hsla(202, 86%, 43%, ": "#0f87cc",
  "hsla(234, 91%, 69%, ": "#6876f8",
  "hsla(214, 100%, 54%, ": "#147aff",
  "hsla(151, 73%, 37%, ": "#19a361",
  "hsla(328, 100%, 71%, ": "#ff6bba",
  "hsla(212, 68%, 45%, ": "#256ec1",
  "hsla(47, 75%, 61%, ": "#e6c651",
  "hsla(41, 42%, 59%, ": "#c2a76b",
  "hsla(0, 71%, 85%, ": "#f4bebe",
  "hsla(0, 39%, 56%, ": "#bb6363",
};
