// User Provider reducer
import { USER_LOCAL_STORAGE_KEY } from "@App/Constants/GlobalConstants";
import useLocalStorage from "@App/Hooks/useLocalStorage";
import {
  AppContextActionType,
  AppContextInitialStateType,
  LayoutStateType,
  UserStateType,
} from "@App/Types/AppContextType";
import { UserAccountType } from "@App/Types/GlobalType";
import { useCallback, useReducer } from "react";

export const UserPersistUseReducer = () => {
  const { value, setItemToLocalstorage, deleteItemFromLocalStorage } =
    useLocalStorage<UserAccountType>(USER_LOCAL_STORAGE_KEY, undefined);

  const reducerLocalStorage = useCallback(
    // as the underlying `reducer` function
    (state: UserStateType, action: AppContextActionType): UserStateType => {
      const newState = UserReducer(state, action);
      if (action.type === "LOGIN_USER") {
        setItemToLocalstorage(newState.user);
      } else {
        deleteItemFromLocalStorage();
      }
      return newState;
    },
    [deleteItemFromLocalStorage, setItemToLocalstorage]
  );

  return useReducer(reducerLocalStorage, {
    user: value,
    error: null,
  });
};

const UserReducer = (state: UserStateType, action: AppContextActionType) => {
  switch (action.type) {
    case "LOGIN_USER":
      return {
        ...state,
        user: action.payload,
        error: null,
      } as UserStateType;

    case "LOGOUT_USER":
      return {
        ...state,
        user: null,
        error: null,
      };

    default:
      return state;
  }
};

const LayoutReducer = (
  state: LayoutStateType,
  action: AppContextActionType
) => {
  console.log(state, action);

  switch (action.type) {
    case "SIDEBAR_TOGGLE":
      return {
        ...state,
        Sidebar: {
          ...state.Sidebar,
          isCollapsed: action.payload.isCollapsed,
          isMobile: false,
          isSemiDark: false,
        },
      } as LayoutStateType;

    case "THEME":
      return {
        ...state,
        Theme: {
          ...state.Theme,
          dark: !action.payload.dark,
        },
      } as LayoutStateType;

    default:
      return state;
  }
};

// Combined reducer with both user and layout state like done in redux
const CombinedReducer = (
  { User, Layout }: AppContextInitialStateType,
  action: AppContextActionType
) => ({
  User: UserReducer(User, action),
  Layout: LayoutReducer(Layout, action),
});
export { UserReducer, CombinedReducer, LayoutReducer };
