import { SnackbarTailwindClass } from "@App/Constants/Tailwind/UIClasses";
import ReactDOM from "react-dom";
import { SnackbarComponentType } from "../../Types/ComponentType";

function SnackbarComponent({
  PositionClass,
  Icon,
  ScreenReaderLabel,
  Label,
  SnackBarType,
  showSnackBar,
  ...props
}: SnackbarComponentType) {
  return ReactDOM.createPortal(
    <div
      className={`px-5 py-2.5 mb-4 text-sm ${
        showSnackBar ? "flex" : "hidden"
      } ${props.className} ${PositionClass} ${
        SnackbarTailwindClass[SnackBarType]
      }`}
      role="alert"
    >
      {<Icon className="flex-shrink-0 inline w-5 h-5 mr-1" />}
      <span className="sr-only">{ScreenReaderLabel}</span>
      <div className="font-medium">{Label}</div>
    </div>,
    document.documentElement
  );
}

export default SnackbarComponent;
