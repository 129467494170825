import { DropDownItemComponentType } from "@App/Types/ComponentType";

const DropDownItemComponent = ({
  className,
  handleOptionClick,
  keyID,
  value,
}: DropDownItemComponentType) => {
  return (
    <button
      key={keyID}
      className="text-left w-full block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
      role="menuitem"
      onClick={() => handleOptionClick(keyID, value)}
    >
      {value}
    </button>
  );
};

export default DropDownItemComponent;
