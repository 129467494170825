import { useEffect, useState } from "react";

function useLocalStorage<T>(key: string, initialValue?: T) {
  const [value, setValue] = useState<T | undefined>(() => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.warn(`Error getting localStorage item "${key}": ${error}`);
      return initialValue;
    }
  });

  useEffect(() => {
    try {
      if (value === undefined) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(value));
      }
    } catch (error) {
      console.warn(`Error setting localStorage item "${key}": ${error}`);
    }
  }, [key, value]);

  function setItemToLocalstorage(item: T) {
    setValue(item);
  }

  function deleteItemFromLocalStorage() {
    setValue(undefined);
  }

  return { value, setValue, setItemToLocalstorage, deleteItemFromLocalStorage };
}

export default useLocalStorage;
