/*File Viewer Component */

import { FileViewerComponentType } from "@App/Types/ComponentType";
import { memo, useEffect, useState } from "react";
import ButtonComponent from "../ButtonComponent";

const FileViewerComponent = ({
  FileURL,
  FileType,
  className = "max-h-[30rem]",
}: FileViewerComponentType) => {
  const [downloadURL, setDownloadURL] = useState(null);
  const [isImage, setIsImage] = useState(false);
  const [isSupported, setIsSupported] = useState(false);

  const SupportedFiles = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
    "image/svg+xml",
    "application/pdf",
  ];

  const ImageFileTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
    "image/svg+xml",
  ];

  // get extension from file type
  const getFileExtension = (fileType: string) => {
    return fileType.split("/")[1];
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = FileURL;
    link.download = `file.${getFileExtension(FileType)}`; // fileURL.split('.').pop() will give extension
    console.log(link);

    link.click();
  };

  useEffect(() => {
    // check file type is supported by the viewer support
    console.log(FileType, FileURL);

    if (SupportedFiles.includes(FileType)) {
      setIsSupported(true);
      if (ImageFileTypes.includes(FileType)) {
        setIsImage(true);
      } else setIsImage(false);
    } else {
      setIsSupported(false);
    }
  }, [FileURL, FileType]);

  return (
    <>
      {isSupported ? (
        <div className={`w-full h-full overflow-auto rounded-lg ${className}`}>
          {isImage ? (
            <img src={FileURL} alt="file" className="rounded-lg" />
          ) : (
            <iframe
              src={FileURL}
              title="File Viewer"
              width="100%"
              height="500px"
            />
          )}
        </div>
      ) : (
        <div className="w-full h-56">
          <div className="flex flex-col items-center justify-center h-full">
            <p className="mb-2 text-2xl tracking-tight font-bold text-gray-900 dark:text-white">
              File Not Supported
            </p>
            <p className="mb-4 text-base font-light text-gray-500 dark:text-gray-400">
              Sorry, File viewer does not support this file type. You can
              download the file.
            </p>
          </div>
        </div>
      )}
      <div className="flex justify-content-end my-3">
        <ButtonComponent
          onClick={handleDownload}
          type="submit"
          buttonStyleType="primary"
          label="Download"
          ClassName="w-full inline items-center text-sm px-5 py-2.5"
        />
      </div>
    </>
  );
};

export default memo(FileViewerComponent);
