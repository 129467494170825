import { InputErrorTailwindClass } from "@Constants/Tailwind/UIClasses";
import { InputErrorMessageComponentType } from "@Types/ComponentType";

export default function InputErrorMessageComponent({
  ErrorMessage,
  ErrorType,
  ClassName,
}: InputErrorMessageComponentType) {
  return (
    <p className={`${InputErrorTailwindClass[ErrorType!]} ${ClassName}`}>
      {ErrorMessage}
    </p>
  );
}
