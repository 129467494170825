import { BASE_URL } from "@App/Constants/GlobalConstants";

/**
 * Get the auth api path.
 *
 * @param {string} path
 * @returns  {string}
 */
export const getBaseUrl = (path: string): string => `${BASE_URL}${path}`;

export const getFormData = (data) => {
  console.log(data);
  const formData = new FormData();

  for (const [key, value] of Object.entries(data)) {
    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        if (item !== null && typeof item === "object") {
          for (const [subKey, subValue] of Object.entries(item)) {
            formData.append(`${key}[${index}][${subKey}]`, subValue as string);
          }
        } else {
          formData.append(`${key}[${index}]`, item);
        }
      });
    } else {
      formData.append(key, value as string);
    }
  }

  console.log(formData);

  return formData;
};
