import { ShimmerComponentType } from "@App/Types/ComponentType";
import React from "react";

function SkeletonComponent<E extends React.ElementType>({
  children,
  count = 1,
  type = "line",
  className = "rounded-sm",
  as,
  width = "",
  height = "h-1",
  isShown = false,
  dynamicElementClassNames = "",
}: ShimmerComponentType<E>) {
  /* skeleton type based dynamic height and width */

  switch (type) {
    case "line":
      className += " w-48 h-1";
      break;

    case "circle":
      className += " rounded-full w-10 h-10";
      break;

    case "container":
      className += " w-full h-[200px]";
      break;
    case "square":
      className += " rounded-sm w-10 h-10";
      break;
    default:
      break;
  }
  console.log(isShown);

  const DynamicElement = as || "div";
  return (
    <>
      <DynamicElement
        className={`${`animate-pulse ${dynamicElementClassNames}`}`}
      >
        <div
          className={`bg-gray-200 dark:bg-gray-700 ${className} ${height} ${width}`}
        >
          {children}
        </div>
      </DynamicElement>
    </>
  );
}

export default React.memo(SkeletonComponent);
