import { NodataComponentType } from "@App/Types/ComponentType";
import { DocumentTextIcon } from "@heroicons/react/20/solid";

const NoDataScreen = ({
  className = "",
  description = "Sorry, there is no data to display at the moment.",
  title = "No Data Available",
}: NodataComponentType) => {
  return (
    <div
      className={`flex flex-col items-center justify-center h-[500px] ${className}`}
    >
      <DocumentTextIcon className="w-12 h-12 text-gray-400 mb-4" />
      <h1 className="text-xl font-semibold text-gray-400 mb-1">{title}</h1>
      <p className="text-lg text-gray-500">{description}</p>
    </div>
  );
};

export default NoDataScreen;
