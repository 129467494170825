// Super Admin Sidebar Layout

import { SidebarModel } from "@App/Types/ComponentType";
import {
  GlobeAsiaAustraliaIcon,
  HomeIcon,
  LinkIcon,
  PlayCircleIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";

export const SUPER_ADMIN_SIDEBAR_DATA: SidebarModel[] = [
  {
    id: 1,
    title: "Dashboard",
    icon: HomeIcon,
    isActive: true,
    MenuLink: "/",
    isChildMenuOpen: false,
    children: [],
    isHome: true,
  },
  {
    id: 2,
    title: "Users Settings",
    icon: UsersIcon,
    isActive: false,
    isChildMenuOpen: false,
    MenuLink: "/users",
    children: [],
    isHome: false,
  },

  {
    id: 3,
    title: "Product Settings",
    icon: GlobeAsiaAustraliaIcon,
    isActive: false,
    isChildMenuOpen: false,
    MenuLink: "/products",
    children: [],
    isHome: false,
  },

  {
    id: 4,
    title: "Menu Links",
    icon: LinkIcon,
    isActive: false,
    MenuLink: "/menu-links",
    isChildMenuOpen: false,
    children: [],
    isHome: false,
  },
  {
    id: 5,
    title: "Tutorials",
    icon: PlayCircleIcon,
    isActive: false,
    MenuLink: "/tutorials",
    isChildMenuOpen: false,
    children: [],
    isHome: false,
  },
];

// Sidebar Layout constants

export const SIDEBAR_MAX_HEIGHT = "256px";
export const SIDEBAR_MIN_HEIGHT = "65px";
