import {
  AppProviderProps,
  LayoutContextType,
  LayoutStateType,
  UserContextType,
} from "@App/Types/AppContextType";
import { createContext, useMemo, useReducer } from "react";
import { LayoutReducer, UserPersistUseReducer } from "./AppReducer";

const isDarkModeEnabled = () => {
  return document.documentElement.classList.contains("dark");
};
export const UserContext = createContext<UserContextType>(undefined);
export const LayoutContext = createContext<LayoutContextType>(undefined);

const AppProvider = ({ children }: AppProviderProps) => {
  // check if dark mode is enabled
  const isDarkMode = isDarkModeEnabled();

  const LayoutInitialState: LayoutStateType = {
    Sidebar: {
      isCollapsed: true,
      isMobile: false,
      isSemiDark: false,
      isVisible: true,

      isPublicPortal: false,
    },
    Theme: {
      dark: isDarkMode,
      direction: "ltr",
      primaryColor: "default",
      routerTransition: "default",
      skin: "default",
    },
  };

  // hooks initial state
  const [userState, userDispatch] = UserPersistUseReducer();
  const [layoutState, layoutDispatch] = useReducer(
    LayoutReducer,
    LayoutInitialState
  );

  const UserMemoizedState = useMemo(
    () => ({
      userState,
      userDispatch,
    }),
    [userState, userDispatch]
  );

  const LayoutMemoizedState = useMemo(
    () => ({
      layoutState,
      layoutDispatch,
    }),
    [layoutState, layoutDispatch]
  );

  return (
    <LayoutContext.Provider value={LayoutMemoizedState}>
      <UserContext.Provider value={UserMemoizedState}>
        {children}
      </UserContext.Provider>
    </LayoutContext.Provider>
  );
};

export { AppProvider };
