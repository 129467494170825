import useAuth from "@App/Hooks/useAuth";
import { Navigate, Outlet, RouteProps, useLocation } from "react-router-dom";

const PrivateRoute = ({ path, ...props }: RouteProps) => {
  const isLoggedIn = useAuth();
  let location = useLocation();
  console.log("state changed");

  if (!isLoggedIn.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="login" state={{ from: location }} />;
  }
  return <Outlet />;
};

export default PrivateRoute;
