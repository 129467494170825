import "@App/index.css";
import RouterWrapper from "@Components/Router/RouterWrapper";
import { AppProvider } from "@Context/AppContext";
import ar from "@Utils/locales/ar.json";
import en from "@Utils/locales/en.json";
import fr from "@Utils/locales/fr.json";
import "firebase/auth";
import { getStorage } from "firebase/storage";
import i18n from "i18next";
import React from "react";
import ReactDOM from "react-dom/client";
import { initReactI18next } from "react-i18next";
import firebase, { firebaseConfig } from "./Utils/firebase";

i18n.use(initReactI18next).init({
  lng: "en",
  interpolation: {
    escapeValue: false, // react already safes from xss!
  },
  resources: {
    en: {
      translation: en,
    },
    fr: {
      translation: fr,
    },
    ar: {
      translation: ar,
    },
  },
});

firebase.initializeApp(firebaseConfig);
export const storage = getStorage();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <AppProvider>
      <RouterWrapper />
    </AppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// hook test
