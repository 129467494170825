import {
  SIDEBAR_MAX_HEIGHT,
  SIDEBAR_MIN_HEIGHT,
  SUPER_ADMIN_SIDEBAR_DATA,
} from "@App/Constants/SidebarConstants";
import { LayoutContext } from "@App/Context/AppContext";
import useFetch from "@App/Hooks/useFetch";
import { LayoutContextType } from "@App/Types/AppContextType";
import { SidebarModel } from "@App/Types/ComponentType";
import {
  PublicPortalMenuLinksType,
  PublicPortalProductModuleType,
} from "@App/Types/GlobalType";
import {
  PublicPortalProductLinksUrl,
  PublicPortalProductModulesUrl,
} from "@App/Utils/apiPaths";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  HomeIcon,
  LinkIcon,
} from "@heroicons/react/20/solid";
import { animated, useSpring, useSprings } from "@react-spring/web";
import React, { useContext, useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useMatch, useParams } from "react-router-dom";

function Sidebar() {
  const params = useParams();
  const [userSidebar, setUserSidebar] = useState<SidebarModel[]>(
    SUPER_ADMIN_SIDEBAR_DATA
  );
  const { layoutState } = useContext<LayoutContextType | undefined>(
    LayoutContext
  );
  const currentSidebarState = layoutState.Sidebar;
  const [isPublicPortal, setPublicPortal] = useState<boolean>(false);
  const location = useLocation();
  const [currentItemSelectedHeight, setCurrentItemSelectedHeight] = useState(0);
  const sidebarMenuRefs = useRef(
    userSidebar.map(() => React.createRef<HTMLUListElement>())
  );
  const { updateFetchInput, setLoading, loading, setError } = useFetch();

  useEffect(() => {
    const publicPortalMenuList = async (productCode: string) => {
      const productModules = await updateFetchInput<
        PublicPortalProductModuleType[]
      >({
        input: PublicPortalProductModulesUrl(productCode),
        init: {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
      });

      const menuLinks = await updateFetchInput<PublicPortalMenuLinksType[]>({
        input: PublicPortalProductLinksUrl(productCode),
        init: {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
      });

      const modules = productModules.response;
      console.log("moduels", modules);

      const links = menuLinks.response;
      console.log("links", links);

      if (modules.length > 0) {
        const PublicPortalSidebarList: SidebarModel[] = modules.map(
          (el, index) => {
            const children = links
              .filter((l) => el.ModuleName === l.ModuleName)
              .map((l) => ({
                id: Number(`${index + 1}.${l.MenuLinkID}`),
                title: l.PrivilegeName,
                icon: LinkIcon,
                isActive: false,
                isChildMenuOpen: false,
                MenuLink: `public/${productCode}/${l.MenuLinkID.toString()}`,
                children: [],
                isHome: false,
              }));

            return {
              id: index + 1,
              title: el.ModuleName,
              icon: LinkIcon,
              isActive: false,
              isChildMenuOpen: false,
              MenuLink: "",
              children: children,
              isHome: false,
            };
          }
        );
        console.log(PublicPortalSidebarList);
        PublicPortalSidebarList.unshift({
          id: 0,
          title: "Dashboard",
          icon: HomeIcon,
          isActive: true,
          MenuLink: "/",
          isChildMenuOpen: false,
          children: [],
          isHome: true,
        });
        setPublicPortal(true);
        setUserSidebar(PublicPortalSidebarList);
      } else {
        setUserSidebar([
          {
            id: 0,
            title: "Dashboard",
            icon: HomeIcon,
            isActive: true,
            MenuLink: "/",
            isChildMenuOpen: false,
            children: [],
            isHome: true,
          },
        ]);
      }
    };
    if (
      location.pathname.includes("/public") &&
      params?.productCode !== undefined
    ) {
      if (!isPublicPortal) {
        publicPortalMenuList(params?.productCode);
      }
    } else {
      setUserSidebar(SUPER_ADMIN_SIDEBAR_DATA);
      setPublicPortal(false);
    }
  }, [location]);

  // parent list active state
  const { pathname } = useLocation();
  const match = useMatch(pathname);

  const toggleChildMenuList = (
    currentItem: SidebarModel,
    parentIndex: number
  ) => {
    setCurrentItemSelectedHeight(
      sidebarMenuRefs?.current[parentIndex].current.scrollHeight
    );
    setUserSidebar((currentState) => {
      let newState = [...currentState];
      return newState.map((item) => {
        if (item.id === currentItem.id) {
          return {
            ...item,
            isChildMenuOpen: !item.isChildMenuOpen,
          };
        } else {
          return {
            ...item,
            isChildMenuOpen: false,
          };
        }
      });
    });
  };

  // React Spring animations
  const sidebarAnimation = useSpring({
    width: currentSidebarState.isCollapsed
      ? SIDEBAR_MIN_HEIGHT
      : SIDEBAR_MAX_HEIGHT,
  });

  const titleAnimation = useSpring({
    opacity: currentSidebarState.isCollapsed ? 0 : 1,
    transform: currentSidebarState.isCollapsed
      ? "translateX(-10px)"
      : "translateX(0px)",
  });

  const springs = useSprings(
    userSidebar.length,
    userSidebar.map((item, i) => {
      return {
        height:
          item.isChildMenuOpen && !currentSidebarState.isCollapsed
            ? `${currentItemSelectedHeight}px`
            : "0px",
        opacity:
          item.isChildMenuOpen && !currentSidebarState.isCollapsed ? 1 : 0,
        from: {
          height: "0px",
          opacity: 0,
        },
      };
    })
  );

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    item: SidebarModel
  ) => {
    console.log(location.pathname);

    if (
      item.children.length <= 0 &&
      location.pathname.includes("/public") &&
      !item.isHome
    ) {
      event.preventDefault();
      return;
    }

    if (item.children.length > 0) {
      event.preventDefault(); // Prevent navigation to item if has children
    }
  };

  /* state for handling sidebar mouse enter and leave */
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);

  const handleEnter = (id: number) => {
    currentSidebarState.isCollapsed && setHoveredItem(id);
  };

  const handleLeave = () => {
    currentSidebarState.isCollapsed && setHoveredItem(null);
  };

  // Dynamic Submenu spring animation rendering
  const subMenuAnimation = (children: SidebarModel[], parentIndex: number) => {
    return (
      <animated.ul
        className="overflow-hidden"
        ref={sidebarMenuRefs?.current[parentIndex]}
        style={springs[parentIndex]}
      >
        {children.map((child) => (
          <li
            key={child.id}
            className={` rounded-lg flex flex-1 bg-white dark:bg-transparent hover:bg-gray-100 dark:hover:bg-gray-700`}
          >
            <NavLink
              to={child.MenuLink}
              className="flex-1 flex p-1 relative overflow-hidden"
            >
              {({ isActive }) => (
                <>
                  <span
                    className={`pl-9 after:absolute after:after-sidebar-border truncate ${
                      isActive
                        ? "text-primary-600 after:bg-primary-500"
                        : "text-gray-500 dark:text-gray-300 after:bg-gray-300"
                    }`}
                  >
                    {child.title}
                  </span>
                  {child.children && child.children.length > 0 && (
                    <ChevronDownIcon
                      className={`h-5 w-5 ml-auto self-center ${
                        isActive
                          ? "fill-white"
                          : "fill-gray-500 dark:fill-gray-300"
                      }`}
                    />
                  )}
                </>
              )}
            </NavLink>
          </li>
        ))}
      </animated.ul>
    );
  };

  return (
    <animated.aside
      style={sidebarAnimation}
      className="fixed top-0 left-0 w-64 h-full p-3 shadow bg-white dark:bg-gray-800 flex justify-between flex-col z-[3]"
      aria-label="Sidenav"
    >
      <div>
        <div className="inline-flex items-center">
          <animated.h2
            className={`text-black text-xl font-bold capitalize tracking-normal pt-3 pl-2 truncate overflow-hidden dark:text-white ${
              currentSidebarState.isCollapsed ? "opacity-0" : "opacity-1"
            } duration-300`}
          >
            MESHIL HELP
          </animated.h2>
        </div>

        <ul className="mt-5">
          {userSidebar.map((item, index) => {
            return (
              <li
                key={item.id}
                className={`flex flex-col flex-1 mb-1 relative`}
                onMouseEnter={() => handleEnter(item.id)}
                onMouseLeave={handleLeave}
              >
                <NavLink
                  to={item.MenuLink}
                  style={{
                    padding: "8px",
                  }}
                  className={`flex-1 flex items-center rounded-lg ${
                    match.pathname === item.MenuLink
                      ? "bg-primary-600"
                      : "dark:hover:bg-gray-700 hover:bg-gray-100"
                  }`}
                  onClick={(e) => handleClick(e, item)}
                >
                  {({ isActive }) => (
                    <>
                      <item.icon
                        height={24}
                        width={24}
                        className={`flex-shrink-0 ${
                          isActive
                            ? "fill-white"
                            : "fill-gray-500 dark:fill-gray-300"
                        }`}
                      />
                      <animated.span
                        className={`truncate ml-2 ${
                          isActive
                            ? "text-white"
                            : "text-gray-500 dark:text-gray-300"
                        } ${
                          currentSidebarState.isCollapsed ? "w-0" : "w-full"
                        }`}
                      >
                        {item.title}
                      </animated.span>

                      {item.children && item.children.length > 0 && (
                        <animated.div
                          className={`select-none flex ml-auto ${
                            currentSidebarState.isCollapsed ? "w-0" : "w-auto"
                          }`}
                          onClick={() => toggleChildMenuList(item, index)}
                        >
                          {item.isChildMenuOpen ? (
                            <ChevronDownIcon
                              className={`h-5 w-5 ml-auto self-center cursor-pointer ${
                                match.pathname === item.MenuLink
                                  ? "fill-white"
                                  : "fill-gray-500 dark:fill-gray-300"
                              }`}
                            />
                          ) : (
                            <ChevronRightIcon
                              className={`h-5 w-5 ml-auto self-center cursor-pointer ${
                                match.pathname === item.MenuLink
                                  ? "fill-white"
                                  : "fill-gray-500 dark:fill-gray-300"
                              }`}
                            />
                          )}
                        </animated.div>
                      )}
                    </>
                  )}
                </NavLink>

                {item.children && item.children.length > 0 && (
                  <ul
                    className={`tooltip ${
                      hoveredItem === item.id && currentSidebarState.isCollapsed
                        ? "block"
                        : "hidden"
                    } absolute top-[10px] left-[50px] w-max truncate max-w-[250px] z-10 shadow-lg rounded-lg bg-white dark:bg-gray-700 py-3 pr-2`}
                  >
                    {item.children?.map((child) => (
                      <li
                        key={child.id}
                        className={`rounded-lg flex flex-1 p-r-[25px]`}
                      >
                        <NavLink
                          to={child.MenuLink}
                          className="flex-1 flex p-1 relative overflow-hidden"
                        >
                          {({ isActive }) => (
                            <>
                              <span
                                className={`pl-6 after:absolute after:after-sidebar-border truncate ${
                                  isActive
                                    ? "text-primary-600 after:bg-primary-500"
                                    : "text-gray-500 dark:text-gray-300 after:bg-gray-300"
                                }`}
                              >
                                {child.title}
                              </span>
                              {child.children && child.children.length > 0 && (
                                <ChevronDownIcon
                                  className={`h-5 w-5 ml-auto self-center ${
                                    isActive
                                      ? "fill-white"
                                      : "fill-gray-500 dark:fill-gray-300"
                                  }`}
                                />
                              )}
                            </>
                          )}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
                {/* </div> */}
                {/* </div> */}

                {subMenuAnimation(item.children, index)}
              </li>
            );
          })}
        </ul>
      </div>
    </animated.aside>
  );
}

export default Sidebar;
