import Sidebar from "@Components/UI/Sidebar";
import Header from "@Components/UI/header";
import { animated, useSpring } from "@react-spring/web";
import { useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import {
  SIDEBAR_MAX_HEIGHT,
  SIDEBAR_MIN_HEIGHT,
} from "./Constants/SidebarConstants";
import { LayoutContext, UserContext } from "./Context/AppContext";
import { LayoutContextType, UserContextType } from "./Types/AppContextType";

export default function App() {
  const location = useLocation();

  /* User & Layout Context */
  const { userState } = useContext<UserContextType | undefined>(UserContext);
  console.log(location);
  const { layoutState } = useContext<LayoutContextType | undefined>(
    LayoutContext
  );

  const userListAnimation = useSpring({
    marginLeft:
      !userState.user?.Token && location.pathname === "/"
        ? "0px"
        : layoutState.Sidebar?.isCollapsed
        ? `${SIDEBAR_MIN_HEIGHT}`
        : `${SIDEBAR_MAX_HEIGHT}`,
  });

  return (
    <section className="bg-white dark:bg-gray-900">
      <Header />
      {!userState.user?.Token && location.pathname === "/" ? null : <Sidebar />}
      <animated.div style={userListAnimation}>
        <Outlet />
      </animated.div>
    </section>
  );
}
