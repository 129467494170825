/* ===============================TYPESCRIPT TYPES=============================== */

/* UI Components Models */
import { AliasesCSSProperties } from "@Types/Tree/AliasesCSSProperties";
import { OverwriteCSSProperties } from "@Types/Tree/OverwriteCSSProperties";
import { StandardCSSProperties } from "@Types/Tree/StandardCSSproperties";
import { TransitionProps } from "@Types/Tree/Transition";
import * as CSS from "@Types/packages/cssType";
import React, { ReactNode } from "react";

// Global Components Types
export type ComponentStyleType =
  | "info"
  | "danger"
  | "success"
  | "warning"
  | "dark";

export type ButtonStyleType = "primary" | "secondary" | "accent";

export type StateIndicatorStyleType =
  | "state-success"
  | "state-warning"
  | "state-danger"
  | "state-info";

// Table Components Style Types
export type TableStyleType = "default" | "bordered" | "striped";
export type TableItemStyleType =
  | "table"
  | "thead"
  | "tbody"
  | "tr"
  | "th"
  | "td";

// Input Components Types
export type InputComponentType = React.InputHTMLAttributes<HTMLInputElement> & {
  InputStyleType: ComponentStyleType;
  LabelName?: string;
  isLabeled?: boolean;
  className?: string;
  parentClassName?: string;
};

// Snackbar Components Types
export type SnackbarComponentType = React.HTMLAttributes<HTMLDivElement> & {
  Label: string;
  PositionClass:
    | "bottom-4 left-4"
    | "bottom-4 right-4"
    | "top-4 left-4"
    | "bottom-0 left-1/2 -translate-x-1/2";
  ScreenReaderLabel: string;
  SnackBarType: ComponentStyleType;
  showSnackBar: boolean;
  Icon: React.ForwardRefExoticComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
      titleId?: string | undefined;
    }
  >;
};

// Input Error Message Components Types
export type InputErrorMessageComponentType = Partial<{
  ErrorMessage: string;
  ErrorType: ComponentStyleType;
  ClassName: string;
}>;

// Alert Components Types

// Input Error Message Components Types
export type StateIndicatorComponentType = {
  label: string;
  StateStyleType: ComponentStyleType | StateIndicatorStyleType;
  IsInfoAlert?: boolean;
  IsIcon?: boolean;
  ClassName: string;
  ScreenReaderLabel: string;
  Icon?: React.ForwardRefExoticComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
      titleId?: string | undefined;
    }
  >;
};

// Sidebar Components Types

export type SidebarModel = {
  id: number;
  title: string;
  icon?: React.ForwardRefExoticComponent<React.SVGProps<SVGSVGElement>>;
  isActive: boolean;
  isChildMenuOpen: boolean;
  MenuLink: string;
  children?: SidebarModel[];
  isHome?: boolean;
};

export type PublicPortalSidebarModel = {
  ModuleName: string;
  Description: string;
  ModuleOrder: string;
  MenuLink: string;
  children: PublicPortalSidebarChildrenModel[];
};

export type PublicPortalSidebarChildrenModel = {
  ModuleID: number;
  MenuLinkID: number;
  PrivilegeName: string;
  MenuLink: string;
};
export type SidebarComponentType = {};

// Button Components Types
export type ButtonComponentType =
  React.ButtonHTMLAttributes<HTMLButtonElement> &
    Partial<{
      type: "reset" | "button" | "submit";
      buttonStyleType: ButtonStyleType;
      label: string;
      isIconButton: boolean;
      ClassName: string;
      Icon?: React.ReactElement<React.SVGProps<SVGSVGElement>>;
      onLoadingState: boolean;
      buttonClickHandler: () => void;
    }>;

// Search Components Types
export type SearchComponentType = Partial<{
  searchStyleType: ComponentStyleType;
  searchText: string;
  handlerFunction: Function;
}>;

/* Menu Components Types
 */

export type MenuComponentType = Partial<{
  MenuIcon: React.ReactElement<React.SVGProps<SVGSVGElement>>;
  MenuID: string;
  dividerElement: ReactNode;
  children: React.ReactNode | React.ReactNode[];
}>;

export type MenuItemComponentType = Partial<{
  itemIcon: React.ReactElement<React.SVGProps<SVGSVGElement>>;
  label: string;
  isDivider: boolean;
  itemHandler: () => void;
}>;

/* Paginator Component types */
export type PaginatorComponentType = Partial<{
  tableData: any;
  totalLength: number; // total length of the items in the table
  pageIndex: number; //  index of the page
  pageStart: number; // start number of the page
  pageEnd: number; // end number of the page
  pageSize: number; // number of items in the page
  previousHandler: (page: number) => void;
  nextHandler: (page: number) => void;

  stateHandler: React.Dispatch<React.SetStateAction<unknown>>;
}>;

/* Dialog Component */
export type DialogComponentType = Partial<{
  isHeader: boolean;
  width: string;
  isOpen: boolean;
  dialogTitle: string;
  dialogHandler: React.Dispatch<React.SetStateAction<unknown>>;
  children: React.ReactNode | React.ReactNode[];
}>;

/* Delete Confirm Component */
export type DeleteConfirmComponentType = Partial<{
  width: string;
  isOpen: boolean;
  confirmTitle: string;
  className: string;
  onLoadingState: boolean;
  closeHandler: React.Dispatch<React.SetStateAction<unknown>>;
  submitHandler: (...args: any[]) => void;
}>;

/* Shimmer Component
1.Container
2.Line
3. Circle
*/

type ShimmerOwnType<E extends React.ElementType> = Partial<{
  type: "line" | "circle" | "container" | "square" | "default";
  className: string;
  dynamicElementClassNames: string;
  isShown: boolean;
  width: string;
  height: string;
  borderRadius: string;
  animation: "animate-pulse";
  as: E;

  // mutiple items
  count: number;
  widthMultiple: string[];
  heightMultiple: string[];

  children?: React.ReactElement | JSX.Element | string;
}>;

export type ShimmerComponentType<E extends React.ElementType> =
  ShimmerOwnType<E> & Omit<React.ComponentProps<E>, keyof ShimmerOwnType<E>>;

/* File Upload Component */

export type DialogActionType = "ADD" | "EDIT" | "DELETE" | "VIEW";

export type FileUploadComponentType = Partial<{
  FormikFormControl: any;
  Action: string;
  FileTypeAllowed: string[];
  FileFullPath: string;
  dynamicFileInputID?: string;
  className?: string;

  FileUploadHandler: (file: File | null, formikState?: any) => void;
  FileViewHandler: (FileURL: string, FileType: string) => void;

  isChange: boolean;
}>;

export type FilehandlerType = Partial<{
  FileList?: { file: File }[];
  DocFile?: File;
  Name?: string;
  FileType?: string;
  FileName?: string;
  FileUploadStatus: string;
  FileSize?: number;
}>;

/* File Viewer Component */

export type FileViewerComponentType = {
  FileURL: string;
  FileType: string;
  FileName?: string;
  className?: string;
};

/* Image Avatar Component */
export type AvatarComponentType = Partial<{
  AvatarURL: string;
  AvatarAlt: string;
  AvatarModel: "circle" | "square";
  AvatarSize: "small" | "medium" | "large";
  AvatarType:
    | "default"
    | "placeholder-icon"
    | "placeholder-initials"
    | "stacked";
  AvatarClassName: string;
  StackedImageList: string[];
  AvatarInitialColorClassName: string;
}>;

/* Chip Input Component Model */
export type ChipInputComponentType = Partial<{
  className: string;
  placeholder: string;
  errorLabel: string;
  label: string;
  action: string;
  chipInputHandler: (chip: string) => void;
}>;

/* DropDown Component Model */
export type DropDownComponentType = Partial<{
  className: string;
  label: string;
  placeholder: string;
  children: React.ReactNode | React.ReactNode[];
  selectedOptionHandler: (key: string, value: string) => void;
}>;

export type DropDownItemComponentType = Partial<{
  className: string;
  keyID: string;
  value: string;
  handleOptionClick: (key: string, value: string) => void;
}>;

/* Nodata Component Model */
export type NodataComponentType = Partial<{
  className: string;
  title: string;
  description: string;
}>;

/* FAB Component Model */
export type FABComponentType = Partial<{
  className: string;
  icon: React.ReactNode;
  onClick: () => void;
  label: string;
}>;

/* Tree Component */

export type AccountGroupModel = Partial<AccountGroup>;

export interface AccountGroup {
  AccountGroupID: number;
  AccountGroupCode: string;
  GroupName: string;
  GroupNameNat: string;
  ShortName: string;
  ShortNameNat: string;
  ParentGroupCode: string;
  ParentGroupID?: number;
  Nature: string;
  LevelID?: number;
  children?: Partial<AccountGroup>[];
  NodeID: number;
  ParentID: number;
  ID: number;
}

/* TreeView Component Model */

/**
 * The `css` function accepts arrays as values for mobile-first responsive styles.
 * Note that this extends to non-theme values also. For example `display=['none', 'block']`
 * will also works.
 */
export type ResponsiveStyleValue<T> =
  | T
  | Array<T | null>
  | { [key: string]: T | null };

/**
 * Map of all available CSS properties (including aliases) and their raw value.
 * Only used internally to map CSS properties to input types (responsive value,
 * theme function or nested) in `SystemCssProperties`.
 */
export interface AllSystemCSSProperties
  extends Omit<StandardCSSProperties, keyof OverwriteCSSProperties>,
    OverwriteCSSProperties,
    AliasesCSSProperties {}

export type SystemCssProperties<Theme extends object = {}> = {
  [K in keyof AllSystemCSSProperties]:
    | ResponsiveStyleValue<AllSystemCSSProperties[K]>
    | ((theme: Theme) => ResponsiveStyleValue<AllSystemCSSProperties[K]>)
    | SystemStyleObject<Theme>;
};

/**
 * Map of all CSS pseudo selectors (`:hover`, `:focus`, ...).
 */
export type CSSPseudoSelectorProps<Theme extends object = {}> = {
  [K in CSS.Pseudos]?:
    | ((theme: Theme) => SystemStyleObject<Theme>)
    | SystemStyleObject<Theme>;
};

type CssVariableType = string | number;

/**
 * Map all nested selectors and CSS variables.
 */
export interface CSSSelectorObjectOrCssVariables<Theme extends object = {}> {
  [cssSelectorOrVariable: string]:
    | ((theme: Theme) => SystemStyleObject<Theme> | string | number)
    | SystemStyleObject<Theme>
    | CssVariableType;
}

/**
 * The `SystemStyleObject` defines custom properties that will be transformed to
 * their corresponding values from the `Theme`. Other valid CSS properties are also allowed.
 */
export type SystemStyleObject<Theme extends object = {}> =
  | SystemCssProperties<Theme>
  | CSSPseudoSelectorProps<Theme>
  | CSSSelectorObjectOrCssVariables<Theme>
  | null;

/**
 * The `SxProps` can be either object or function
 */
export type SxProps<Theme extends Object = {}> =
  | SystemStyleObject<Theme>
  | ((theme: Theme) => SystemStyleObject<Theme>)
  | ReadonlyArray<
      | boolean
      | SystemStyleObject<Theme>
      | ((theme: Theme) => SystemStyleObject<Theme>)
    >;

export interface TreeViewPropsBase
  extends React.HTMLAttributes<HTMLUListElement> {
  /**
   * The Name of the component, it is used for className prefix
   */
  componentName?: "CustomTreeView";
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
  /**
   * Override or extend the styles applied to the component.
   */
  classes?: Partial<TreeViewClasses>;
  /**
   * The default icon used to collapse the node.
   */
  defaultCollapseIcon?: React.ReactNode;
  /**
   * The default icon displayed next to a end node. This is applied to all
   * tree nodes and can be overridden by the TreeItem `icon` prop.
   */
  defaultEndIcon?: React.ReactNode;
  /**
   * Expanded node ids. (Uncontrolled)
   * @default []
   */
  defaultExpanded?: string[];
  /**
   * The default icon used to expand the node.
   */
  defaultExpandIcon?: React.ReactNode;
  /**
   * The default icon displayed next to a parent node. This is applied to all
   * parent nodes and can be overridden by the TreeItem `icon` prop.
   */
  defaultParentIcon?: React.ReactNode;
  /**
   * If `true`, will allow focus on disabled items.
   * @default false
   */
  disabledItemsFocusable?: boolean;
  /**
   * If `true` selection is disabled.
   * @default false
   */
  disableSelection?: boolean;
  /**
   * Expanded node ids. (Controlled)
   */
  expanded?: string[];
  /**
   * This prop is used to help implement the accessibility logic.
   * If you don't provide this prop. It falls back to a randomly generated id.
   */
  id?: string;
  /**
   * Callback fired when tree items are focused.
   *
   * @param {React.SyntheticEvent} event The event source of the callback **Warning**: This is a generic event not a focus event.
   * @param {string} value of the focused node.
   */
  onNodeFocus?: (event: React.SyntheticEvent, nodeId: string) => void;
  /**
   * Callback fired when tree items are expanded/collapsed.
   *
   * @param {React.SyntheticEvent} event The event source of the callback.
   * @param {array} nodeIds The ids of the expanded nodes.
   */
  onNodeToggle?: (event: React.SyntheticEvent, nodeIds: string[]) => void;
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx?: SxProps;
}

export interface MultiSelectTreeViewProps extends TreeViewPropsBase {
  /**
   * Selected node ids. (Uncontrolled)
   * When `multiSelect` is true this takes an array of strings; when false (default) a string.
   * @default []
   */
  defaultSelected?: string[];
  /**
   * Selected node ids. (Controlled)
   * When `multiSelect` is true this takes an array of strings; when false (default) a string.
   */
  selected?: string[];
  /**
   * If true `ctrl` and `shift` will trigger multiselect.
   * @default false
   */
  multiSelect?: true;
  /**
   * Callback fired when tree items are selected/unselected.
   *
   * @param {React.SyntheticEvent} event The event source of the callback
   * @param {string[] | string} nodeIds Ids of the selected nodes. When `multiSelect` is true
   * this is an array of strings; when false (default) a string.
   */
  onNodeSelect?: (event: React.SyntheticEvent, nodeIds: string[]) => void;
}

export interface SingleSelectTreeViewProps extends TreeViewPropsBase {
  /**
   * Selected node ids. (Uncontrolled)
   * When `multiSelect` is true this takes an array of strings; when false (default) a string.
   * @default []
   */
  defaultSelected?: string;
  /**
   * Selected node ids. (Controlled)
   * When `multiSelect` is true this takes an array of strings; when false (default) a string.
   */
  selected?: string;
  /**
   * If true `ctrl` and `shift` will trigger multiselect.
   * @default false
   */
  multiSelect?: false;
  /**
   * Callback fired when tree items are selected/unselected.
   *
   * @param {React.SyntheticEvent} event The event source of the callback
   * @param {string[] | string} nodeIds Ids of the selected nodes. When `multiSelect` is true
   * this is an array of strings; when false (default) a string.
   */
  onNodeSelect?: (event: React.SyntheticEvent, nodeIds: string) => void;
}

export type TreeViewProps =
  | SingleSelectTreeViewProps
  | MultiSelectTreeViewProps;

export interface TreeViewClasses {
  /** Styles applied to the root element. */
  root: string;
}

export type TreeViewClassKey = keyof TreeViewClasses;

/* TreeItem Component Model */
// StandardProps<React.HTMLAttributes<HTMLLIElement>, 'onFocus'>
export interface TreeItemProps
  extends Omit<React.HTMLAttributes<HTMLLIElement>, "onFocus"> {
  /**
   * The Name of the component, it is used for className prefix
   */
  componentName?: "CustomTreeItem";
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
  /**
   * Override or extend the styles applied to the component.
   */

  classes?: Partial<TreeItemClasses>;
  /**
   * The icon used to collapse the node.
   */
  collapseIcon?: React.ReactNode;
  /**s
   * The component used for the content node.
   * @default TreeItemContent
   */
  // ContentComponent?: React.JSXElementConstructor<TreeItemContentProps>;
  /**
   * Props applied to ContentComponent
   */
  ContentProps?: React.HTMLAttributes<HTMLElement>;
  /**
   * If `true`, the node is disabled.
   */
  disabled?: boolean;
  /**
   * The icon displayed next to a end node.
   */
  endIcon?: React.ReactNode;
  /**
   * The icon used to expand the node.
   */
  expandIcon?: React.ReactNode;
  /**
   * The icon to display next to the tree node's label.
   */
  icon?: React.ReactNode;
  /**
   * This prop isn't supported.
   * Use the `onNodeFocus` callback on the tree if you need to monitor a node's focus.
   */
  onFocus?: null;
  /**
   * The tree node label.
   */
  label?: React.ReactNode;
  /**
   * The id of the node.
   */
  nodeId: string;

  /**
   * the label Code of the node.
   */
  labelCode: string;

  /**
   * label code split by dot length
   */
  level?: number;

  /**
   * dataId for other usecase like data fetching.
   */

  dataId?: number | string;

  /**
   * Node extra values
   */
  nodeValues?: any;

  /**
   * The component used for the transition.
   * [Follow this guide](/material-ui/transitions/#transitioncomponent-prop) to learn more about the requirements for this component.
   * @default Collapse
   */
  TransitionComponent?: React.JSXElementConstructor<TransitionProps>;
  /**
   * Props applied to the transition element.
   * By default, the element is based on this [`Transition`](http://reactcommunity.org/react-transition-group/transition/) component.
   */
  TransitionProps?: TransitionProps;
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx?: SxProps;
}

export interface TreeItemClasses {
  /** Styles applied to the root element. */
  root: string;
  /** Styles applied to the transition component. */
  group?: string;
  /** Styles applied to the content element. */
  content?: string;
  /** State class applied to the content element when expanded. */
  expanded?: string;
  /** State class applied to the content element when selected. */
  selected?: string;
  /** State class applied to the content element when focused. */
  focused?: string;
  /** State class applied to the element when disabled. */
  disabled?: string;
  /** Styles applied to the tree node icon. */
  iconContainer?: string;
  /** Styles applied to the label element. */
  label?: string;
}

export type TreeItemClassKey = keyof TreeItemClasses;

/**
 it is assumed that this variable will be provided by some external source,
  such as a third-party library or the global scope.
 */
declare const treeItemClasses: TreeItemClasses;
export default treeItemClasses;

/* ===============================TYPESCRIPT ENUMS=============================== */

export enum FileUploadStatusEnum {
  "warning" = "Oops!, File Not Supported!..",
  "success" = "File Added Succesfully!..",
  "failure" = "Something went wrong!..",
  "overflow" = "Files should not exceed 10 MB",
  "notfound" = "File not found!..",
}

export enum PageType {
  "ADD" = "add",
  "REMOVE" = "delete",
  "WARNING" = "warning",
  "EDIT" = "edit",
  "VIEW" = "view",
}

export enum StyleType {
  Info = "info",
  Danger = "danger",
  Success = "success",
  Warning = "warning",
  Dark = "dark",
}
